<template>
    <div>
        <div>
            <div :class=" ismsg == false ?  'Checklist_Section_width fixdheader': 'fixdheader_checklist' ">
                <span class="headerstyle">Checklist-Section</span>
                <span class="right">
                        <el-button icon="el-icon-back" v-if="!ismsg" type="info" @click="$router.go(-1)">Back</el-button>
                        <el-button  v-if="ismsg" type="info" @click="$emit('messageclose', {ismsg:false})">Close</el-button>
                        <el-button v-if="sectionid != null" @click="cellchanged('analysis_checklistsection_save',()=>{save()},false)" :disabled="!editpermission" type="success">Save</el-button>
                </span>
                <el-row>
                    <span class="left" >
                        <selectpart  :Similar="Similar" @projectpartchange="projectpartchange">
                        </selectpart>
                    </span>
                    <span  class="left">
                        <el-select :disabled="sectiondisabled" v-model="sectionid" filterable @change="GetChecklistSection" :id="'analysis_checklistsection_filter_section'" placeholder="Section"    
                        style="width:244px;" class="padding2px bold">
                            <el-option v-for="item in sections" :key="item.id" :label="item.sectiondisplay"
                                :value="item.id" :disabled="item.islinethrough == true ? false : true">
                                <div :class="!item.islinethrough  ? 'islinethrough' : ''">{{item.sectiondisplay}}</div>
                            </el-option>
                        </el-select>
                    </span>
                    <span style="margin-left: 6px; margin-top: 4px;" class="left">
                        <div class="el-input el-input--mini bold " v-if="project.library">
                            {{ project.library }}
                        </div>
                    </span>
                </el-row>
                <hr class="hrstyle" />
                <div v-if="sectionid != null">
                    <el-row>
                        <el-select v-model="form.role" filterable placeholder="Role" :id="'analysis_checklistsection_filter_role'">
                            <el-option v-for="item in ['Take Off', 'Bill']" :disabled="!editpermission" :key="item"
                                :label="item" :value="item">
                            </el-option>
                        </el-select>

                        <el-select v-model="clsfiltervalue" v-if="!ismsg" placeholder="Section heading" class="marginleft4px" :id="'analysis_checklistsection_filter_sectionheading'" :style="{width: clsfilterwidth}" clearable>
                            <el-option v-for="item in form.section" :disabled="!editpermission" :key="item.id" :label="item.srno + '. ' +item.sectionname" :value="item.id"></el-option>
                        </el-select>
                    </el-row>
                    <hr class="hrstyle" />
                </div>
            </div>
            <div v-if="sectionid != null" :class="ismsg == false ? 'Checklist_Section_width' : ''">
                <el-row>
                    <el-col>
                        <div class="var_crt">
                            <span style="font:bold;">Variations and Corrections</span>
                        </div>

                    </el-col>
                </el-row>
                <el-row>
                    <el-table :data="form.elementlistVCs" class="fullwidth custom_padding " size="mini">

                        <el-table-column prop="variationnumber" label="Val" width="55">
                            <template slot-scope="scope">
                                <el-input :disabled="!editpermission" v-model="scope.row.variationnumber" :id="'analysis_checklistsection_table_val'+scope.$index"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column prop="correctionnumber" label="Crtn" width="55">
                            <template slot-scope="scope">
                                <el-input :disabled="!editpermission" v-model="scope.row.correctionnumber" :id="'analysis_checklistsection_table_crtn'+scope.$index"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column prop="datestarted" label="Date Started" width="105" min-width="90">
                            <template slot-scope="scope">
                                <el-date-picker v-model="scope.row.datestarted" align="center" type="date" style="width:100px"
                                    :id="'analysis_checklistsection_table_datastarted'+scope.$index"
                                    prefix-icon="{}" format="dd-MMM-yyyy" value-format="yyyy-MM-dd"
                                    placeholder="Date Started">
                                </el-date-picker>
                            </template>
                        </el-table-column>
                        <el-table-column prop="description" label="Description">
                            <template slot-scope="scope">
                                <el-input :disabled="!editpermission" v-model="scope.row.description" :id="'analysis_checklistsection_table_description'+scope.$index">
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column align="right" label="Action" width="100">
                            <template slot-scope="scope">
                                    <el-button size="mini" @click="Addsection(scope.$index, scope.row)"
                                        class="padding7TB" :disabled="!editpermission" type="primary"
                                        icon="el-icon-circle-plus"></el-button>
                                    <el-button size="mini" class="padding7TB" :disabled="!editpermission" type="danger"
                                        icon="el-icon-delete" @click="delRowSection(scope.$index, scope.row)">
                                    </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
                <hr class="hrstyle" />
                <el-row>
                    <el-col>
                        <div class="var_crt">
                            <span style="font:bold;">Sections</span>
                        </div>
                    </el-col>
                </el-row>
                <el-table :data="[{}]" class="tableBox-cell">
                    <el-table-column width="25"></el-table-column>
                    <el-table-column label="T" width="25"></el-table-column>
                    <el-table-column label="B" width="25"></el-table-column>
                    <el-table-column label="Date Stamp" width="115"></el-table-column>
                    <el-table-column label="List"></el-table-column>
                    <el-table-column label="Answer / Link" align="left" width="220"></el-table-column>
                    <el-table-column label="Unit / UR(S)" align="center" width="75"></el-table-column>
                    <!-- <el-table-column label="Biller" align="center" width="100"></el-table-column> -->
                    <el-table-column label="Library" align="center" width="85"></el-table-column>
                    <el-table-column label="LR" align="center" width="35"></el-table-column>
                </el-table>
                <el-table :show-header="false" ref="sectiontable" :data="getchecklistsections"
                    class="fullwidth custom_padding tableBox section_table_header" size="mini"
                    :cell-style="mastertablecellClassName" :row-class-name="masterTableRowClassName" default-expand-all>
                    <el-table-column type="expand" width="25">
                        <template slot-scope="pt" class="fullwidth">
                            <div v-if="pt.row.sectiondetails && pt.row.sectiondetails.length > 0">
                            <el-table :show-header="false" key="id" :data="pt.row.sectiondetails"
                                class="fullwidth custom_padding tableBox" :cell-style="tablecellClassName"
                                ref="sectiondetail" default-expand-all>
                                <el-table-column type="expand" width="25">
                                    <template slot-scope="Ascope">
                                        <div v-if="getChildren(Ascope.row).length > 0">
                                        <el-table :show-header="false" :data="Ascope.row.children"
                                            class="fullwidth custom_padding tableBox" :cell-style="tablecellClassName"
                                            :row-style="tableRowStyle" ref="Ascope" default-expand-all>
                                            <el-table-column type="expand" width="25">
                                                <template slot-scope="Bscope">
                                                    <div v-if="getChildren(Bscope.row).length > 0">
                                                    <el-table :show-header="false" :data="Bscope.row.children"
                                                        class="fullwidth custom_padding tableBox"
                                                        :cell-style="tablecellClassName" ref="Bscope" 
                                                        :row-style="tableRowStyle" default-expand-all>
                                                        <el-table-column type="expand" width="25">
                                                            <template slot-scope="Cscope">
                                                                <div v-if="getChildren(Cscope.row).length > 0">
                                                                <el-table :show-header="false" row-key="srno"
                                                                    :data="getChildren(Cscope.row)"
                                                                    class="fullwidth custom_padding tableBox"
                                                                    :cell-style="tablecellClassName" ref="Cscope">
                                                                    <el-table-column width="25"></el-table-column>
                                                                    <el-table-column prop="srno" width="25">
                                                                        <template slot-scope="scope">
                                                                            <el-checkbox :disabled="form.role == 'Bill'"
                                                                                @change="signoffbilltick($event, scope.row, Cscope.row, 'children','takeoff')"
                                                                                :id="'analysis_checklistsection_table_cscope_bill_checkbox'+pt.$index+Ascope.$index+Bscope.$index+Cscope.$index+scope.$index"
                                                                                v-model="scope.row.cs.takeoff">
                                                                            </el-checkbox>
                                                                        </template>
                                                                    </el-table-column>
                                                                    <el-table-column prop="srno" width="25">
                                                                        <template slot-scope="scope">
                                                                            <el-checkbox
                                                                                :disabled="form.role == 'Take Off'"
                                                                                @change="signoffbilltick($event, scope.row, Cscope.row, 'children','bill')"
                                                                                :id="'analysis_checklistsection_table_cscope_tackoff_checkbox'+pt.$index+Ascope.$index+Bscope.$index+Cscope.$index+scope.$index"
                                                                                v-model="scope.row.cs.bill">
                                                                            </el-checkbox>
                                                                        </template>
                                                                    </el-table-column>
                                                                    <el-table-column prop="datestarted"
                                                                        label="Date Started" width="115">
                                                                        <template slot-scope="scope">
                                                                            {{ dateformat(scope.row.cs.date) }}
                                                                            <!-- <el-date-picker v-model="scope.row.cs.date"
                                                                                type="date" style="width:100px"
                                                                                prefix-icon="{}" format="dd-MMM-yyyy"
                                                                                value-format="yyyy-MM-dd"
                                                                                placeholder="Date Started">
                                                                            </el-date-picker> -->
                                                                        </template>
                                                                    </el-table-column>
                                                                    <el-table-column prop="activity" label="List" show-overflow-tooltip>
                                                                        <template slot-scope="scope">                                                                            
                                                                            <div v-if="scope.row.rowtype === 'E' || scope.row.rowtype === 'F'">
                                                                                <el-input :class="'row_color_'+scope.row.rowtype"
                                                                                           :disabled="!editpermission"
                                                                                           placeholder="Activity"
                                                                                           v-model="scope.row.cs.activity"></el-input>
                                                                            </div>
                                                                            <div v-else>
                                                                                {{ scope.row.activity }}
                                                                            </div>
                                                                        </template>
                                                                    </el-table-column>
                                                                    <el-table-column label="MOM/Answer" align="left"
                                                                        prop="method" width="220" show-overflow-tooltip>
                                                                        <template slot-scope="scope">
                                                                            <!-- <div v-if="scope.row.rowtype === 'D'">
                                                                                {{ scope.row.method }}
                                                                            </div> -->
                                                                            <div v-if="scope.row.rowtype === 'G' && scope.row.sourcelink">
                                                                                <el-link type="primary" :href="scope.row.sourcelink" target="_blank" class="fontsize12" :underline="false">{{ scope.row.sourcelink }}</el-link>
                                                                            </div>
                                                                            <!-- <div v-if="scope.row.rowtype === 'F' && scope.row.answer">
                                                                                <el-dropdown>
                                                                                    <span class="el-dropdown-link">
                                                                                        Answer's
                                                                                        <i
                                                                                            class="el-icon-arrow-down el-icon--right"></i>
                                                                                    </span>
                                                                                    <el-dropdown-menu slot="dropdown">
                                                                                        <div v-for="(itme, index) in getanswer(scope.row.answer)"
                                                                                            :key="index">
                                                                                            <el-dropdown-item divided>
                                                                                                {{ itme }}
                                                                                            </el-dropdown-item>
                                                                                        </div>
                                                                                    </el-dropdown-menu>
                                                                                </el-dropdown>
                                                                            </div> -->
                                                                            <div v-if="scope.row.rowtype === 'F'">
                                                                                <el-select style="width:100%;"
                                                                                    placeholder="Select Answer from List"
                                                                                    default-first-option
                                                                                    filterable
                                                                                    allow-create
                                                                                    :clearable="false"
                                                                                    v-model="scope.row.cs.answer"
                                                                                    v-if="scope.row.rowtype === 'F'"
                                                                                    :class="'row_color_'+scope.row.rowtype">
                                                                                    <el-option
                                                                                        v-for="(item, index) in scope.row.cs.templateanswers"
                                                                                        :key="index"
                                                                                        :label="item"
                                                                                        :value="item">
                                                                                    </el-option>
                                                                                </el-select>
                                                                            </div>
                                                                            <div v-if="scope.row.rowtype === 'E'">
                                                                                <!-- {{ scope.row.answer }} -->
                                                                                <el-input 
                                                                                    placeholder="Answer"
                                                                                    v-model="scope.row.cs.answer"
                                                                                    :disabled="!editpermission"
                                                                                    v-if="scope.row.rowtype === 'E'"
                                                                                    :class="'row_color_'+scope.row.rowtype"></el-input>
                                                                            </div>
                                                                        </template>
                                                                    </el-table-column>
                                                                    <el-table-column prop="activity" width="70"
                                                                        label="Unit/UR(S)">
                                                                        <template slot-scope="scope">
                                                                            <div v-if="scope.row.rowtype === 'D'">
                                                                                {{ scope.row.unit }}
                                                                            </div>
                                                                            <div v-if="scope.row.rowtype === 'E' || scope.row.rowtype === 'F'">
                                                                                <el-select
                                                                                    v-model="scope.row.cs.urs"
                                                                                    :class="'row_color_'+scope.row.rowtype"
                                                                                    clearable
                                                                                    remote
                                                                                    :remote-method="getURsRemote"
                                                                                    :disabled="!editpermission"
                                                                                    reserve-keyword
                                                                                    filterable
                                                                                    placeholder="UR(S)">
                                                                                    <el-option
                                                                                        v-for="(item, index) in $store.state.URs"
                                                                                        :key="index"
                                                                                        :label="item.ur"
                                                                                        :value="item.ur">
                                                                                    </el-option>
                                                                                </el-select>
                                                                            </div>
                                                                        </template>
                                                                    </el-table-column>
                                                                    <!-- <el-table-column label="Biller" prop="rowtype"
                                                                        align="center" width="100">
                                                                        <template slot-scope="scope">
                                                                            {{ scope.row.cs.billbyname }}
                                                                        </template>
                                                                    </el-table-column> -->
                                                                    <el-table-column label="Library" align="center" width="85">
                                                                        <template slot-scope="scope">
                                                                            {{ scope.row.method }}
                                                                        </template>
                                                                    </el-table-column>
                                                                    <el-table-column label="LR" align="center" width="35">
                                                                        <template slot-scope="scope">
                                                                            {{ addLeadingZeros(scope.row.LR, 3) }}
                                                                        </template>
                                                                    </el-table-column>
                                                                </el-table>
                                                                </div>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column prop="srno" width="25">
                                                            <template slot-scope="scope">
                                                                <el-checkbox :disabled="form.role == 'Bill'"
                                                                :id="'analysis_checklistsection_table_bscope_bill_checkbox'+pt.$index+Ascope.$index+Bscope.$index+scope.$index"
                                                                    @change="signoffbilltick($event, scope.row, Bscope.row, 'children','takeoff')"
                                                                    v-model="scope.row.cs.takeoff">
                                                                </el-checkbox>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column prop="srno" width="25">
                                                            <template slot-scope="scope">
                                                                <el-checkbox :disabled="form.role == 'Take Off'"
                                                                :id="'analysis_checklistsection_table_bscope_tackoff_checkbox'+pt.$index+Ascope.$index+Bscope.$index+scope.$index"
                                                                    @change="signoffbilltick($event, scope.row, Bscope.row, 'children','bill')"
                                                                    v-model="scope.row.cs.bill"></el-checkbox>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column prop="datestarted" label="Date Started"
                                                            width="115">
                                                            <template slot-scope="scope">
                                                                <!-- <el-date-picker v-model="scope.row.cs.date" type="date"
                                                                    style="width:100px" prefix-icon="{}"
                                                                    format="dd-MMM-yyyy" value-format="yyyy-MM-dd"
                                                                    placeholder="Date Started">
                                                                </el-date-picker> -->
                                                                {{ dateformat(scope.row.cs.date) }}
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column prop="activity" label="List">
                                                            <template slot-scope="scope">
                                                                <div class="sectionfont_C"> {{ scope.row.activity }}
                                                                </div>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column label="MOM / Answer" align="left" width="1">
                                                        </el-table-column>
                                                        <el-table-column label="Unit / UR(S)" prop="unit" width="1">
                                                        </el-table-column>
                                                        <!-- <el-table-column label="Biller" prop="rowtype" align="center"
                                                            width="100">
                                                            <template slot-scope="scope">
                                                                {{ scope.row.cs.billbyname }}
                                                            </template>
                                                        </el-table-column> -->
                                                        <el-table-column label="LR" prop="LR" align="center" width="35">
                                                            <template slot-scope="scope">
                                                                {{ addLeadingZeros(scope.row.LR, 3) }}
                                                            </template>
                                                        </el-table-column>
                                                    </el-table>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="srno" width="25">
                                                <template slot-scope="scope">
                                                    <el-checkbox :disabled="form.role == 'Bill'"
                                                    :id="'analysis_checklistsection_table_ascope_bill_checkbox'+pt.$index+Ascope.$index+scope.$index"
                                                        @change="signoffbilltick($event, scope.row, Ascope.row, 'children','takeoff')"
                                                        v-model="scope.row.cs.takeoff"></el-checkbox>
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="srno" width="25">
                                                <template slot-scope="scope">
                                                    <el-checkbox :disabled="form.role == 'Take Off'"
                                                    :id="'analysis_checklistsection_table_ascope_tackoff_checkbox'+pt.$index+Ascope.$index+scope.$index"
                                                        @change="signoffbilltick($event, scope.row, Ascope.row, 'children','bill')"
                                                        v-model="scope.row.cs.bill"></el-checkbox>
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="datestarted" label="Date Started" width="115">
                                                <template slot-scope="scope">
                                                    <!-- <el-date-picker v-model="scope.row.cs.date" type="date"
                                                        style="width:100px" prefix-icon="{}" format="dd-MMM-yyyy"
                                                        value-format="yyyy-MM-dd" placeholder="Date Started">
                                                    </el-date-picker> -->
                                                    {{ dateformat(scope.row.cs.date) }}
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="activity" label="List">
                                                <template slot-scope="scope">
                                                    <div class="sectionfont_B"> {{ scope.row.activity }} </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="MOM / Answer" align="left" width="1">
                                            </el-table-column>
                                            <el-table-column label="Unit / UR(S)" prop="unit" width="1">
                                            </el-table-column>
                                            <!-- <el-table-column label="Biller" prop="rowtype" align="center" width="100">
                                                <template slot-scope="scope">
                                                    {{ scope.row.cs.billbyname }}
                                                </template>
                                            </el-table-column> -->
                                            <el-table-column label="LR" prop="LR" align="center" width="35">
                                                <template slot-scope="scope">
                                                    {{ addLeadingZeros(scope.row.LR, 3) }}
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="srno" width="25">
                                    <template slot-scope="scope">
                                        <el-checkbox :disabled="form.role == 'Bill'"
                                        :id="'analysis_checklistsection_table_sectiondetail_tackoff_checkbox'+pt.$index+scope.$index"
                                            @change="signoffbilltick($event, scope.row, pt.row, 'sectiondetails','takeoff')"
                                            v-model="scope.row.cs.takeoff"></el-checkbox>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="srno" width="25">
                                    <template slot-scope="scope">
                                        <el-checkbox :disabled="form.role == 'Take Off'"
                                        :id="'analysis_checklistsection_table_sectiondetail_bill_checkbox'+pt.$index+scope.$index"
                                            @change="signoffbilltick($event, scope.row, pt.row, 'sectiondetails','bill')"
                                            v-model="scope.row.cs.bill"></el-checkbox>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="datestarted" label="Date Started" width="115">
                                    <template slot-scope="scope">
                                        <!-- <el-date-picker v-model="scope.row.cs.date" type="date" style="width:100px"
                                            prefix-icon="{}" format="dd-MMM-yyyy" value-format="yyyy-MM-dd"
                                            placeholder="Date Started">
                                        </el-date-picker> -->
                                        {{ dateformat(scope.row.cs.date) }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="activity" label="List">
                                    <template slot-scope="scope">
                                        <div class="sectionfont_A"> {{ scope.row.activity }} </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="MOM / Answer" align="left" width="1"></el-table-column>
                                <el-table-column label="Unit / UR(S)" prop="unit" width="1"></el-table-column>
                                <!-- <el-table-column label="Biller" prop="rowtype" align="center" width="100">
                                    <template slot-scope="scope">
                                        {{ scope.row.cs.billbyname }}
                                    </template>
                                </el-table-column> -->
                                <el-table-column label="LR" prop="LR" align="center" width="35">
                                    <template slot-scope="scope">
                                        {{ addLeadingZeros(scope.row.LR, 3) }}
                                    </template>
                                </el-table-column>
                            </el-table>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="srno" width="25" style="font-size: small;font-weight: 600;">
                    </el-table-column>
                    <el-table-column label="List" prop="sectionname" :disabled="!editpermission"
                        style="font-size: small;font-weight: 500;">
                        <template slot-scope="scope">
                            {{ scope.row.sectionname }}
                        </template>
                    </el-table-column>
                    <el-table-column label="MOM / Answer" align="left" width="1"></el-table-column>
                    <el-table-column label="Unit / UR(S)" prop="unit" width="1"></el-table-column>
                    <!-- <el-table-column label="Biller" prop="rowtype" align="center" width="100">
                    </el-table-column> -->
                </el-table>
                <hr class="hrstyle" />
                <el-row class="spaceBetween">
                    <b>Biller :</b> {{this.form.lasteditedbillername}}
                </el-row>
                <el-row>
                    <span class="left">
                        <el-button size="mini" @click="signoffsection()" class="padding7TB" :disabled="!editpermission"
                            type="primary">Sign Off</el-button>
                    </span>
                    <span :class="form.signoffs && form.signoffs.length > 2 ? 'left fixwidthchecked' : 'left'">
                        <div v-for="item in form.signoffs">
                            <div>
                                &nbsp;<b>Checked:</b> &nbsp; <span style="color:#909399;font-size:12px;">{{
                                        item.signoffbyname}} &nbsp;
                                    {{ dateformat(item.signoffon) }}</span>
                            </div>
                        </div>
                    </span>
                </el-row>
            </div>
        </div>
    </div>
</template>
<script>
import selectpart from "./selectpart.vue";
import moment from "moment";
export default {
    props: {
        ismsg:{type: Boolean, default: false},
        pageaction:{type:Object,default:null}
    },
    data() {
        return {
            sectiondisabled:false,
            user: this.$store.state.user,
            form: {
                role: 'Take Off',
                elementlistVCs: [{}],
                section: [{}],
            },
            urlparams: {
                projectid: null, partid: null, sectionid: null
            },
            sections: [],
            sectionid: null,
            project: {},
            Similar: {
                projectPlaceholder: '',
                partPlaceholder: '',
                lastsavedshow: false,
                projectdisabled:false,
                partdisabled:false,
            },
            clsfiltervalue: null,
            clsfilterwidth: '160px',
            directionids:[
                'analysis_checklistsection_filter_section',
                'analysis_checklistsection_filter_role',
                'analysis_checklistsection_filter_sectionheading',
                'analysis_checklistsection_table_val',
                'analysis_checklistsection_table_crtn',
                'analysis_checklistsection_table_datastarted',
                'analysis_checklistsection_table_description',
                'analysis_checklistsection_table_sectiondetail_tackoff_checkbox',
                'analysis_checklistsection_table_sectiondetail_bill_checkbox',
                'analysis_checklistsection_table_ascope_tackoff_checkbox',
                'analysis_checklistsection_table_ascope_bill_checkbox',
                'analysis_checklistsection_table_bscope_tackoff_checkbox',
                'analysis_checklistsection_table_bscope_bill_checkbox',
                'analysis_checklistsection_table_cscope_tackoff_checkbox',
                'analysis_checklistsection_table_cscope_bill_checkbox',
            ],
        }
    },
    components: {
        selectpart: selectpart,
    },
    computed: {
        dateformat() {
            return function (data) {
                if (data) {
                    var dd = new Date(data); 
                    return moment(dd).format("DD-MMM-YYYY  hh:mm") //+ " " + moment().utc(data).format("hh:mm");
                } else {
                    return "";
                }
            }
        },
        getChildren() {
            return function (data) {
                if (!data.children || data.children.length == 0) {
                    return [];
                }
                return data.children;
            }
        },
        getchecklistsections() {
            if (this.form.section.length == 0) {
                return [];
            }
            var sectiondata = this.form.section;
            var clsections = sectiondata.filter((f) => f.id === this.clsfiltervalue);

            if (clsections.length > 0) {
                var clsectionname = clsections[0].sectionname;
                var size = this.measureTextWidth(clsectionname, 14) + 15;
                size = size < 150 ? 150 : size;
                this.clsfilterwidth = size.toString() + "px";
                return clsections;
            } else {
                this.clsfilterwidth = "160px";
            }
            return sectiondata;
        }
    },
    methods: {
        signoffsection() {
            this.form.signoffs.unshift({ projectsectionid: this.sectionid, signoffby: this.user.id, signoffbyname: this.user.loginname, signoffon: new Date() })
        },
        signoffbilltick(event, row, obj, name, type) {
            var tt = new Date();
            const date = moment.utc(tt).format();
            if (event == true) {
                row.cs.date = date;
                if(type == 'bill')
                {
                    row.cs.billon = date;
                    row.cs.billby = this.user.id;
                }
                if(type == 'takeoff')
                {
                    row.cs.takeoffon = date;
                    row.cs.takeoffby = this.user.id;
                }
            }
            if (row.cs.bill == false && row.cs.takeoff == false) {
                row.cs.date = null;
            }
            var t = JSON.parse(JSON.stringify(obj[name]));
            this.$set(obj, name, t);
        },
        FormatChildren(data, action) {
            if (!data.children || data.children.length == 0) {
                return data;
            } else {
                data.children.forEach((c, index) => {
                    if (action == "save") {
                        if (c.cs.bill == true && (c.cs.billby == 0 || c.cs.billby == null)) {
                            c.cs.billby = this.user.id;
                            c.cs.billon = new Date();
                        }
                        if (!c.cs.bill) {
                            c.cs.billby = null;
                            c.cs.billon = null;
                        }
                        if (c.cs.takeoff == true && (c.cs.takeoffby == 0 || c.cs.takeoffby == null)) {
                            c.cs.takeoffby = this.user.id;
                            c.cs.takeoffon = new Date();
                        }
                        if (!c.cs.takeoff) {
                            c.cs.takeoffby = null;
                            c.cs.takeoffon = null;
                        }
                    }
                    if (action == "get") {              
                        if (c.rowtype == 'F') {
                            c.cs.templateanswers = JSON.parse(c.cs.templateanswer);
                        }
                        if (c.cs.billby > 0) {
                            this.$nextTick(() => {
                                this.$set(c.cs,'bill',true);
                                // c.cs.bill = true;
                            })
                        } else {
                            // c.cs.billby = 0;
                            this.$set(c.cs,'bill',false);
                            this.$set(c.cs,'billby',0);
                            // c.cs.bill = false;
                        }
                        if (c.cs.takeoffby > 0) {
                            this.$nextTick(() => {
                                this.$set(c.cs,'takeoff',true);
                                // c.cs.takeoff = true;
                            });
                        } else {
                            this.$set(c.cs,'takeoff',false);
                            this.$set(c.cs,'takeoffby',0);
                            // c.cs.takeoffby = 0;
                            // c.cs.takeoff = false;
                        }
                    }
                    this.FormatChildren(c, action);
                });
            }
        },

        getsection(){
            if(this.sectionid){
                let s = this.sections.find(x => x.id == this.sectionid);
                return s.section;
            }else{
                return "";
            }
        },

        cellchanged(cellaction, callback, cancall) {
            const app = this;
            this.$directionutility.setcomponent(app);
            if (cancall) {
                callback();
                this.canmatchcellid(()=>{},cellaction,this.getsection(),true,(array1)=>{this.validatedirection(array1,callback)} );
            } else {
                this.canmatchcellid(callback,cellaction,this.getsection(),true,(array1)=>{this.validatedirection(array1,callback)});
            }
        },

        save() {
            this.form.section.forEach(e => {
                e.sectiondetails.forEach(ed => {
                    if (ed.cs.bill == true && (ed.cs.billby == 0 || ed.cs.billby == null)) {
                        ed.cs.billby = this.user.id;
                        ed.cs.billon = new Date();
                    }
                    if (!ed.cs.bill) {
                        ed.cs.billby = null;
                        ed.cs.billon = null;
                    }
                    if (ed.cs.takeoff == true && (ed.cs.takeoffby == 0 || ed.cs.takeoffby == null)) {
                        ed.cs.takeoffby = this.user.id;
                        ed.cs.takeoffon = new Date();
                    }
                    if (!ed.cs.takeoff) {
                        ed.cs.takeoffby = null;
                        ed.cs.takeoffon = null;
                    }
                    this.FormatChildren(ed, 'save');
                });
            });
            this.$http.post("elementlist/saveChecklistSection", this.form)
                .then(response => {
                    this.$message({
                        showClose: true,
                        message: "Save Successful!", type: "success"
                    });
                    this.GetChecklistSection(this.sectionid);
                    this.$signalr.checkingdialogboxheaderpage();
                }).catch(err => {
                    this.loading = false;
                    this.$bus.$emit('error', err);
                });
        },
        delRowSection(index, row) {
            this.form.elementlistVCs.splice(index, 1);
            if (this.form.elementlistVCs.length == 0) {
                this.form.elementlistVCs.push({});
            }
        },
        Addsection(index, row) {
            this.form.elementlistVCs.splice(index + 1, 0, { description: '', projectsectionid: this.sectionid });
        },
        masterTableRowClassName({ row, rowIndex }) {
            return 'section-format';
        },
        mastertablecellClassName({ row, column, rowIndex, columnIndex }) {
            if (columnIndex == 6) {
                return {
                    "font-size": "12px"
                }
            }

            return {"background":"#014f46", "color": "white"}
        },
        tablecellClassName: function ({ row, column, rowIndex, columnIndex }) {
            if (row) {
                if (row.rowtype === 'A') {
                    if (columnIndex == 6) {
                        return {
                            background: "#027869",
                            color: "white",
                            "font-size": "12px"
                        }
                    }
                    return {
                        background: "#027869",
                        color: "white",
                    };

                } else if (row.rowtype === 'B') {
                    if (columnIndex == 6) {
                        return {
                            background: "#03cfb5",
                            color: "black",
                            "font-size": "12px"
                        }
                    }
                    return {
                        background: "#03cfb5",
                        color: "black"
                    };
                } else if (row.rowtype === 'C') {
                    if (columnIndex == 6) {
                        return {
                            background: "#0ffdf4",
                            color: "black",
                            "font-size": "12px"
                        }
                    }
                    return {
                        background: "#0ffdf4",
                        color: "black"
                    };
                } else if (row.rowtype !== 'A' && row.rowtype !== 'B' && row.rowtype !== 'C') {
                    if (columnIndex == 6) {
                        return {
                            background: "#f8f8ff",
                            color: "black",
                            "font-size": "12px"
                        }
                    }
                    return {
                        background: "#f8f8ff",
                        color: "black"
                    };
                }
            }
            return '';
        },
        GetChecklistSection: function (sectionid) {
            var self = this;
            self.loading = true;
            self.sectionid = sectionid;
            let tt = self.sections.find(x => x.id == sectionid);
            if(tt != undefined){
                this.$store.state.sectiondisplay =  tt.sectiondisplay;
            }
            var obj = {};
            if(this.pageaction){
                obj = this.pageaction;
            }            
            this.$http.post("elementlist/GetChecklistSection?elementtemplatid=" + tt.elementtemplatid + "&sectionid=" + sectionid+'&ismsg='+this.ismsg,obj)
                .then(response => {
                    let Oldrole = this.form.role;
                    this.form = response.data;
                    this.form.role = Oldrole;
                    this.form.id = this.sectionid;
                    if (this.form.elementlistVCs == undefined || this.form.elementlistVCs.length == 0) {
                        this.form.elementlistVCs = [{ description: '', projectsectionid: this.sectionid }];
                    }
                    this.form.section.forEach(e => {
                        e.sectiondetails.forEach(ed => {
                            if (ed.cs.billby > 0) {
                                ed.cs.bill = true;
                            } else {
                                ed.cs.billby = 0;
                                ed.cs.bill = false;
                            }
                            if (ed.cs.takeoffby > 0) {
                                ed.cs.takeoff = true;
                            } else {
                                ed.cs.takeoffby = 0;
                                ed.cs.takeoff = false;
                            }
                            this.FormatChildren(ed, 'get');
                        });
                    });
                }).catch(err => {
                    this.loading = false;
                    this.$bus.$emit('error', err);
                });
        },
        getsectionforchecklist() {
            this.post("ElementList/getelementlisttemplatebytype?type=" + 3,).then(
                (response) => {
                    var section = [];
                    response.data.forEach(e => {
                        this.sections.forEach(x => {
                            // if(!x.hide && !x.locked){
                                 if (x.section == e.nrmelementid && e.formtype == this.project.library) {
                                     x.elementtemplatid = e.id;
                                     x.islinethrough = true;
                                 }
                                 if(this.project.projectpart.id == x.projectPartId){
                                     var isfind = section.find(t => t.id == x.id); 
                                     if(!isfind){
                                        // if(this.$store.state.sectiondisplay == x.sectiondisplay && x.islinethrough){
                                        //     this.sectionid = x.id;
                                        //     this.GetChecklistSection(x.id);
                                        // }
                                      section.push(x);
                                     }
                                 }
                        //   }
                        });
                        // section.push(...li);
                    });
                    var isssfind = section.find(x => this.$store.state.sectiondisplay == x.sectiondisplay && x.islinethrough);
                    if(isssfind){
                        this.sectionid = isssfind.id;
                        this.GetChecklistSection(isssfind.id);
                    }
                    if(response.data.length > 0){
                       this.sections = JSON.parse(JSON.stringify(section));
                    }else{
                        this.sections = this.sections.filter(x => x.projectPartId == this.project.projectpart.id);
                    }
                });
        },
        projectpartchange: function (Projectobj) {
            this.project.id = Projectobj.project.id;
            this.project = Projectobj.project;
            this.project.projectpart = Projectobj.part;
            if (this.project.projectname == "") {
                this.sectionid = null;
                this.$store.state.projectsections = [];
            }
            else {
                this.sections = [];
                this.sections = this.$store.state.projectsections;
                if (!isNaN(this.urlparams.sectionid) && this.urlparams.sectionid != null) {
                    this.$store.state.projectsections = this.project.projectsectionsview.filter(s => s.projectPartId == this.project.projectpart.id);
                    this.sections = this.$store.state.projectsections;
                    let newsection = this.sections.find(x => x.id === this.urlparams.sectionid);
                    this.$store.state.sectiondisplay = newsection.sectiondisplay;
                    this.urlparams = { projectid: null, partid: null, sectionid: null };
                }
            }
            if (this.sections.length > 0) {
                this.getsectionforchecklist();
            }
        },

        tableRowStyle: function ({ row }) {
            if (row.rowtype == "B" || row.rowtype == "C") {
                if (!row.activity) {
                    return { 'display' : 'none' }
                }
            }
        },

        validatedirection: function(directions, callback) {
                var sectionexp = false;
                var sdexp = false;
                var aexp = false;
                var bexp = false;
                // var cexp = false;
                let $tablesection = this.$refs.sectiontable;
                let $tablesectiondetail = this.$refs.sectiondetail;
                let $tableAscope = this.$refs.Ascope;
                let $tableBscope = this.$refs.Bscope;
                // let $tableCscope = this.$refs.Cscope;
                var errors = [];

                for (let i = 0; i < directions.length; i++) {
                const direction = directions[i];

                var sectiondr = this.getcellmatcheddirection(direction, this.directionids[0]);
                var roledr = this.getcellmatcheddirection(direction, this.directionids[1]);
                var sectionheadingdr = this.getcellmatcheddirection(direction, this.directionids[2]);
                var valdr = this.getcellmatcheddirection(direction, this.directionids[3]);
                var crtndr = this.getcellmatcheddirection(direction, this.directionids[4]);
                var datestarteddr = this.getcellmatcheddirection(direction, this.directionids[5]);
                var descriptiondr = this.getcellmatcheddirection(direction, this.directionids[6]);
                var sectiondetailtakeoffdr = this.getcellmatcheddirection(direction, this.directionids[7]);
                var sectiondetailbilldr = this.getcellmatcheddirection(direction, this.directionids[8]);
                var ascopetakeoffdr = this.getcellmatcheddirection(direction, this.directionids[9]);
                var ascopebilldr = this.getcellmatcheddirection(direction, this.directionids[10]);
                var bscopetakeoffdr = this.getcellmatcheddirection(direction, this.directionids[11]);
                var bscopebilldr = this.getcellmatcheddirection(direction, this.directionids[12]);
                var cscopetakeoffdr = this.getcellmatcheddirection(direction, this.directionids[13]);
                var cscopebilldr = this.getcellmatcheddirection(direction, this.directionids[14]);

                var uniqueid = 0;

                let cellobjarray = [
                    { cellname: "section", sectiondr: sectiondr },
                    { cellname: "role", roledr: roledr },
                    { cellname: "sectionheading", sectionheadingdr: sectionheadingdr }
                ];
                let iscellrulesmatched = false;

                if (sectiondr) {
                    iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors);
                    let dr = this.getformat(sectiondr);
                    var currentsection = this.sections.find(x => x.id == this.sectionid);
                    let ddata = this.getCellValueAndRegexRules(sectiondr, currentsection.section);

                    if(currentsection.section && this.$directionutility.directionvaluematch(ddata) == false){
                        const option = this.getdirectionoption1(sectiondr);
                        Object.assign(option, dr);
                        this.$set(option, "uniqueid", uniqueid)
                        this.$set(option, "elementid", sectiondr.pagecell.cellname);
                        option.validatedirectionsingle = (data)=>{this.validatedirectionsingle(data)}

                        if (iscellrulesmatched == false) {
                            option.ismatchedrulecell = false;
                        } 
                        else 
                        {
                            option.ismatchedrulecell = true;
                        }
                        errors.push(option);
                        uniqueid++;
                    }
                }

                if (roledr) {
                    iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors);
                    let dr = this.getformat(roledr);
                    let ddata = this.getCellValueAndRegexRules(roledr, this.form.role);

                    if(this.form.role && this.$directionutility.directionvaluematch(ddata) == false){
                        const option = this.getdirectionoption1(roledr);
                        Object.assign(option, dr);
                        this.$set(option, "uniqueid", uniqueid)
                        this.$set(option, "elementid", roledr.pagecell.cellname);
                        option.validatedirectionsingle = (data)=>{this.validatedirectionsingle(data)}

                        if (iscellrulesmatched == false) {
                            option.ismatchedrulecell = false;
                        } 
                        else 
                        {
                            option.ismatchedrulecell = true;
                        }
                        errors.push(option);
                        uniqueid++;
                    }
                }

                if (sectionheadingdr) {
                    iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors);
                    let dr = this.getformat(sectionheadingdr);
                    var shvalue = this.form.section.find(x => x.id == this.clsfiltervalue);
                    let ddata = this.getCellValueAndRegexRules(sectionheadingdr, shvalue);

                    if(shvalue && this.$directionutility.directionvaluematch(ddata) == false){
                        const option = this.getdirectionoption1(sectionheadingdr);
                        Object.assign(option, dr);
                        this.$set(option, "uniqueid", uniqueid)
                        this.$set(option, "elementid", sectionheadingdr.pagecell.cellname);
                        option.validatedirectionsingle = (data)=>{this.validatedirectionsingle(data)}

                        if (iscellrulesmatched == false) {
                            option.ismatchedrulecell = false;
                        } 
                        else 
                        {
                            option.ismatchedrulecell = true;
                        }
                        errors.push(option);
                        uniqueid++;
                    }
                }

                this.form.elementlistVCs.forEach((elvc, elindex) => {
                    let cellobjarray = [
                        { cellname: "val", valdr: valdr },
                        { cellname: "crtn", crtndr: crtndr },
                        { cellname: "datestarted", datestarteddr: datestarteddr },
                        { cellname: "description", descriptiondr: descriptiondr }
                    ];
                    let iscellrulesmatched = false;

                    if(valdr){
                        iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors);
                        let dr = this.getformat(valdr);
                        let ddata = this.getCellValueAndRegexRules(valdr, elvc.variationnumber);

                        if(elvc.variationnumber && this.$directionutility.directionvaluematch(ddata) == false){
                            const option = this.getdirectionoption1(valdr);
                            Object.assign(option, dr);
                            this.$set(option, "uniqueid", uniqueid)
                            this.$set(option, "elementid", valdr.pagecell.cellname+elindex);
                            option.validatedirectionsingle = (data)=>{this.validatedirectionsingle(data)}

                            if (iscellrulesmatched == false) {
                                option.ismatchedrulecell = false;
                            } 
                            else 
                            {
                                option.ismatchedrulecell = true;
                            }
                            errors.push(option);
                            uniqueid++;
                        }
                    }

                    if(crtndr){
                        iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors);
                        let dr = this.getformat(crtndr);
                        let ddata = this.getCellValueAndRegexRules(crtndr, elvc.variationnumber);

                        if(elvc.variationnumber && this.$directionutility.directionvaluematch(ddata) == false){
                            const option = this.getdirectionoption1(crtndr);
                            Object.assign(option, dr);
                            this.$set(option, "uniqueid", uniqueid)
                            this.$set(option, "elementid", crtndr.pagecell.cellname+elindex);
                            option.validatedirectionsingle = (data)=>{this.validatedirectionsingle(data)}

                            if (iscellrulesmatched == false) {
                                option.ismatchedrulecell = false;
                            } 
                            else 
                            {
                                option.ismatchedrulecell = true;
                            }
                            errors.push(option);
                            uniqueid++;
                        }
                    }

                    if(datestarteddr){
                        iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors);
                        let dr = this.getformat(datestarteddr);
                        let ddata = this.getCellValueAndRegexRules(datestarteddr, elvc.variationnumber);

                        if(elvc.variationnumber && this.$directionutility.directionvaluematch(ddata) == false){
                            const option = this.getdirectionoption1(datestarteddr);
                            Object.assign(option, dr);
                            this.$set(option, "uniqueid", uniqueid)
                            this.$set(option, "elementid", datestarteddr.pagecell.cellname+elindex);
                            option.validatedirectionsingle = (data)=>{this.validatedirectionsingle(data)}

                            if (iscellrulesmatched == false) {
                                option.ismatchedrulecell = false;
                            } 
                            else 
                            {
                                option.ismatchedrulecell = true;
                            }
                            errors.push(option);
                            uniqueid++;
                        }
                    }

                    if(descriptiondr){
                        iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors);
                        let dr = this.getformat(descriptiondr);
                        let ddata = this.getCellValueAndRegexRules(descriptiondr, elvc.variationnumber);

                        if(elvc.variationnumber && this.$directionutility.directionvaluematch(ddata) == false){
                            const option = this.getdirectionoption1(descriptiondr);
                            Object.assign(option, dr);
                            this.$set(option, "uniqueid", uniqueid)
                            this.$set(option, "elementid", descriptiondr.pagecell.cellname+elindex);
                            option.validatedirectionsingle = (data)=>{this.validatedirectionsingle(data)}

                            if (iscellrulesmatched == false) {
                                option.ismatchedrulecell = false;
                            } 
                            else 
                            {
                                option.ismatchedrulecell = true;
                            }
                            errors.push(option);
                            uniqueid++;
                        }
                    }
                });

                var activityAdr = this.getcellmatcheddirection(direction, this.directionids[2]);  

                var activityCLRdr = this.getcellmatcheddirection(direction, this.directionids[7]);
                const findobj = {};
                findobj.activityDdr = this.getcellmatcheddirection(direction, this.directionids[8]);
                findobj.activityEdr = this.getcellmatcheddirection(direction, this.directionids[9]);
                findobj.activityFdr = this.getcellmatcheddirection(direction, this.directionids[10]);
                findobj.activityGdr = this.getcellmatcheddirection(direction, this.directionids[11]);
                findobj.activityHdr = this.getcellmatcheddirection(direction, this.directionids[12]);

                findobj.activityDLRdr = this.getcellmatcheddirection(direction, this.directionids[13]);
                findobj.activityELRdr = this.getcellmatcheddirection(direction, this.directionids[14]);
                findobj.activityFLRdr = this.getcellmatcheddirection(direction, this.directionids[15]);
                findobj.activityGLRdr = this.getcellmatcheddirection(direction, this.directionids[16]);
                findobj.activityHLRdr = this.getcellmatcheddirection(direction, this.directionids[17]);

                findobj.activityDlibrarydr = this.getcellmatcheddirection(direction, this.directionids[18]);
                findobj.activityElibrarydr = this.getcellmatcheddirection(direction, this.directionids[19]);
                findobj.activityFlibrarydr = this.getcellmatcheddirection(direction, this.directionids[20]);
                findobj.activityGlibrarydr = this.getcellmatcheddirection(direction, this.directionids[21]);
                findobj.activityHlibrarydr = this.getcellmatcheddirection(direction, this.directionids[22]);

                findobj.activityDunitdr = this.getcellmatcheddirection(direction, this.directionids[23]);

                findobj.activityEurdr = this.getcellmatcheddirection(direction, this.directionids[24]);
                findobj.activityFurdr = this.getcellmatcheddirection(direction, this.directionids[25]);


                if(this.form.section != undefined){
                    this.form.section.forEach((s,sind) => {
                        sectionexp = false;

                        if(s.sectiondetails != undefined){
                            s.sectiondetails.forEach((sd,sdind)=>{
                                sdexp = false;

                                let cellobjarray = [
                                    { cellname: "sectiondetailbill", sectiondetailbilldr: sectiondetailbilldr },
                                    { cellname: "sectiondetailtakeoff", sectiondetailtakeoffdr: sectiondetailtakeoffdr }
                                ];
                                let iscellrulesmatched = false;

                                if(sectiondetailbilldr){
                                    iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors);
                                    let dr = this.getformat(sectiondetailbilldr);
                                    let ddata = this.getCellValueAndRegexRules(sectiondetailbilldr, sd.cs.bill);

                                    if(this.$directionutility.directionvaluematch(ddata) == false){
                                        const indexobj = this.setindex(uniqueid,"sectiondetail",sind,sdind)
                                        const option = this.getdirectionoption(sectiondetailbilldr,dr,indexobj,"cs.bill");

                                        if (iscellrulesmatched == false) {
                                            option.ismatchedrulecell = false;
                                        } 
                                        else 
                                        {
                                            option.ismatchedrulecell = true;
                                        }

                                        errors.push(option);
                                        uniqueid ++;
                                        sectionexp = this.rowexpand($tablesection,s,sectionexp);
                                    }
                                }

                                if(sectiondetailtakeoffdr){
                                    iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors);
                                    let dr = this.getformat(sectiondetailtakeoffdr);
                                    let ddata = this.getCellValueAndRegexRules(sectiondetailtakeoffdr, sd.cs.takeoff);

                                    if(this.$directionutility.directionvaluematch(ddata) == false){
                                        const indexobj = this.setindex(uniqueid,"sectiondetail",sind,sdind)
                                        const option = this.getdirectionoption(sectiondetailtakeoffdr,dr,indexobj,"cs.takeoff");
                                        if (iscellrulesmatched == false) {
                                            option.ismatchedrulecell = false;
                                        } 
                                        else 
                                        {
                                            option.ismatchedrulecell = true;
                                        }
                                        errors.push(option);
                                        uniqueid ++;
                                        sectionexp = this.rowexpand($tablesection,s,sectionexp);
                                    }
                                }

                                if(sd.children != undefined){
                                    sd.children.forEach((chB,Bindex) => {

                                        let cellobjarray = [
                                            { cellname: "ascopebill", ascopebilldr: ascopebilldr },
                                            { cellname: "ascopetakeoff", ascopetakeoffdr: ascopetakeoffdr }
                                        ];
                                        let iscellrulesmatched = false;

                                        if(ascopebilldr){
                                            iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors);
                                            let dr = this.getformat(ascopebilldr);
                                            let ddata = this.getCellValueAndRegexRules(ascopebilldr, sd.cs.bill);

                                            if(this.$directionutility.directionvaluematch(ddata) == false){
                                                const indexobj = this.setindex(uniqueid,"A",sind,sdind,Bindex)
                                                const option = this.getdirectionoption(ascopebilldr,dr,indexobj,"cs.bill");
                                                if (iscellrulesmatched == false) {
                                                    option.ismatchedrulecell = false;
                                                } 
                                                else 
                                                {
                                                    option.ismatchedrulecell = true;
                                                }
                                                errors.push(option);
                                                sectionexp = this.rowexpand($tablesection,s,sectionexp);
                                                sdexp = this.rowexpand($tablesectiondetail,sd,sdexp);
                                                uniqueid ++;
                                            }
                                        }

                                        if(ascopetakeoffdr){
                                            iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors);
                                            let dr = this.getformat(ascopetakeoffdr);
                                            let ddata = this.getCellValueAndRegexRules(ascopetakeoffdr, sd.cs.takeoff);

                                            if(this.$directionutility.directionvaluematch(ddata) == false){
                                                const indexobj = this.setindex(uniqueid,"A",sind,sdind,Bindex)
                                                const option = this.getdirectionoption(ascopetakeoffdr,dr,indexobj,"cs.takeoff");
                                                if (iscellrulesmatched == false) {
                                                    option.ismatchedrulecell = false;
                                                } 
                                                else 
                                                {
                                                    option.ismatchedrulecell = true;
                                                }
                                                errors.push(option);
                                                sectionexp = this.rowexpand($tablesection,s,sectionexp);
                                                sdexp = this.rowexpand($tablesectiondetail,sd,sdexp);
                                                uniqueid ++;
                                            }
                                        }

                                        if(chB.children != undefined){
                                                chB.children.forEach((chC,Cindex) => {
                                                    if(bscopebilldr){
                                                        iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors);
                                                        let dr = this.getformat(bscopebilldr);
                                                        let ddata = this.getCellValueAndRegexRules(bscopebilldr, sd.cs.bill);

                                                        if(this.$directionutility.directionvaluematch(ddata) == false){
                                                            const indexobj = this.setindex(uniqueid,"B",sind,sdind, Bindex, Cindex)
                                                            const option = this.getdirectionoption(bscopebilldr,dr,indexobj,"cs.bill");
                                                            if (iscellrulesmatched == false) {
                                                                option.ismatchedrulecell = false;
                                                            } 
                                                            else 
                                                            {
                                                                option.ismatchedrulecell = true;
                                                            }
                                                            errors.push(option);
                                                            sectionexp = this.rowexpand($tablesection,s,sectionexp);
                                                            sdexp = this.rowexpand($tablesectiondetail,sd,sdexp);
                                                            uniqueid ++;
                                                        }
                                                    }

                                                    if(bscopetakeoffdr){
                                                        iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors);
                                                        let dr = this.getformat(bscopetakeoffdr);
                                                        let ddata = this.getCellValueAndRegexRules(bscopetakeoffdr, sd.cs.takeoff);

                                                        if(this.$directionutility.directionvaluematch(ddata) == false){
                                                            const indexobj = this.setindex(uniqueid,"B",sind,sdind, Bindex, Cindex)
                                                            const option = this.getdirectionoption(bscopetakeoffdr,dr,indexobj,"cs.takeoff");
                                                            if (iscellrulesmatched == false) {
                                                                option.ismatchedrulecell = false;
                                                            } 
                                                            else 
                                                            {
                                                                option.ismatchedrulecell = true;
                                                            }
                                                            errors.push(option);
                                                            sectionexp = this.rowexpand($tablesection,s,sectionexp);
                                                            sdexp = this.rowexpand($tablesectiondetail,sd,sdexp);
                                                            uniqueid ++;
                                                        }
                                                    }

                                                    if(chC.children != undefined){
                                                             
                                                        chC.children.forEach((chD,Dindex) => {
                                                            if(cscopebilldr){
                                                                iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors);
                                                                let dr = this.getformat(cscopebilldr);
                                                                let ddata = this.getCellValueAndRegexRules(cscopebilldr, sd.cs.bill);

                                                                if(this.$directionutility.directionvaluematch(ddata) == false){
                                                                    const indexobj = this.setindex(uniqueid,"C",sind,sdind, Bindex, Cindex, Dindex)
                                                                    const option = this.getdirectionoption(cscopebilldr,dr,indexobj,"cs.bill");

                                                                    if (iscellrulesmatched == false) 
                                                                    {
                                                                        option.ismatchedrulecell = false;
                                                                    } 
                                                                    else 
                                                                    {
                                                                        option.ismatchedrulecell = true;
                                                                    }

                                                                    errors.push(option);
                                                                    sectionexp = this.rowexpand($tablesection,s,sectionexp);
                                                                    sdexp = this.rowexpand($tablesectiondetail,sd,sdexp);
                                                                    uniqueid ++;
                                                                }
                                                            }

                                                            if(cscopetakeoffdr){
                                                                iscellrulesmatched = this.multicelllocationrulesmatch(cellobjarray, direction, errors);
                                                                let dr = this.getformat(cscopetakeoffdr);
                                                                let ddata = this.getCellValueAndRegexRules(cscopetakeoffdr, sd.cs.takeoff);

                                                                if(this.$directionutility.directionvaluematch(ddata) == false){
                                                                    const indexobj = this.setindex(uniqueid,"C",sind,sdind, Bindex, Cindex, Dindex)
                                                                    const option = this.getdirectionoption(cscopetakeoffdr,dr,indexobj,"cs.takeoff");

                                                                    if (iscellrulesmatched == false) 
                                                                    {
                                                                        option.ismatchedrulecell = false;
                                                                    } 
                                                                    else 
                                                                    {
                                                                        option.ismatchedrulecell = true;
                                                                    }

                                                                    errors.push(option);
                                                                    sectionexp = this.rowexpand($tablesection,s,sectionexp);
                                                                    sdexp = this.rowexpand($tablesectiondetail,sd,sdexp);
                                                                    uniqueid ++;
                                                                }
                                                            }
                                                        });
                                                    }
                                                    
                                                });
                                            }
                                    });
                                }           
                            })
                        }
                    });
                }
            }

            // errors = errors.filter(x => x.ismatchedrulecell == true);

            if(errors.length > 0){
                this.$store.state.directionsdialogue = errors;
                this.$store.state.currentdirectionindex = 0;
                this.$store.state.currentdirection = errors[0];
            }
            else
            {
                callback();
            }
        },

        validatedirectionsingle(data){
            var vall = "";
            if(data.rowtype == "section"){
                vall = this.getPropertyByString(this.form.section[data.sectionindex],data.checkstr)
            }
            if(data.rowtype == "sectiondetail"){
                vall = this.getPropertyByString(this.form.section[data.sectionindex].sectiondetails[data.sectiondetailindex],data.checkstr)
            }
            if(data.rowtype == "A"){
                vall = this.getPropertyByString(this.form.section[data.sectionindex].sectiondetails[data.sectiondetailindex].children[data.Bindex],data.checkstr)
            }
            if(data.rowtype == "B"){
                vall = this.getPropertyByString(this.form.section[data.sectionindex].sectiondetails[data.sectiondetailindex].children[data.Bindex].children[data.Cindex],data.checkstr)
            }
            if(data.rowtype == "C" || data.rowtype == "E" || data.rowtype == "F" || data.rowtype == "G" || data.rowtype == "H"){
                vall = this.getPropertyByString(this.form.section[data.sectionindex].sectiondetails[data.sectiondetailindex].children[data.Bindex].children[data.Cindex].children[data.Dindex],data.checkstr)
            }
            if(vall != undefined){
                data.value = vall;
            }
        },

        getPropertyByString(obj, propString) {
            const propArray = propString.split('.');
            let currentObj = obj;
            
            for (let i = 0; i < propArray.length; i++) {
                const prop = propArray[i];
                currentObj = currentObj[prop];

                if (currentObj === undefined) 
                {
                    return undefined;
                }
            }
            return currentObj;
        },

        getformat(dr){
            const data = {};
            var inc_pattern = null;
            var exc_pattern = null;
            var format_pattern = null;
            if(dr.includerule != null && dr.includerule != ""){
                inc_pattern = new RegExp(dr.includerule,"i");
            }
            if(dr.excluderule != null && dr.excluderule != ""){
                exc_pattern = new RegExp(dr.excluderule,"i");
            }
            if(dr.formatrule != null && dr.formatrule != ""){
                format_pattern = new RegExp(dr.formatrule,"i");
            }
            data.format_pattern = format_pattern;
            data.inc_pattern = inc_pattern;
            data.exc_pattern = exc_pattern;
            return data;
        },

        getCellValueAndRegexRules(data, cellvalue) {
            if (data.includerule != null && data.includerule != "") {
                data.inc_pattern = new RegExp(data.includerule, "i");
            }

            if (data.excluderule != null && data.excluderule != "") {
                data.exc_pattern = new RegExp(data.excluderule, "i");
            }

            if (data.formatrule != null && data.formatrule != "") {
                data.format_pattern = new RegExp(data.formatrule, "i");
            }

            data.enteredvalue = cellvalue;

            return data;
        },

        setindex(uniqueid,rowtype,sind,sdind,Bindex,Cindex,Dindex){
            var indexobj = {};
            indexobj.uniqueid = uniqueid;
            indexobj.rowtype = rowtype;
            if(sind != undefined)
                indexobj.sectionindex = sind;
            if(sdind != undefined)
                indexobj.sectiondetailindex = sdind;
            if(Bindex != undefined)
                indexobj.Bindex = Bindex;
            if(Cindex != undefined)
                indexobj.Cindex = Cindex;
            if(Dindex != undefined)
                indexobj.Dindex = Dindex;
            return indexobj
        },
        
        rowexpand(refobj,row,checkrow){
            if(checkrow == false){
                if(refobj != undefined){
                    refobj.toggleRowExpansion(row,true);
                }
            }
            return true;
        },

        getcellmatcheddirection(direction, directionidvalue) {
            let matcheddirection = direction.directioncelldetails.find(x => x.pagecell.cellname == directionidvalue);
            let dir = JSON.parse(JSON.stringify(direction));
            if (matcheddirection) {
                dir.includerule = matcheddirection.includerule;
                dir.excluderule = matcheddirection.excluderule;
                dir.formatrule = matcheddirection.formatrule;
                dir.pagecell = matcheddirection.pagecell;
                dir.pagecellid = matcheddirection.pagecellid;

                dir.checkvalue = matcheddirection.checkvalue;
                dir.operation = matcheddirection.operation;
                dir.celltype = matcheddirection.pagecell.celltype;
                dir.objectpath = matcheddirection.pagecell.objectpath;

                return dir;
            }
        },

        getdirectionoption1(FormData){
            const option = JSON.parse(JSON.stringify({
                visible: true,
                sectionname:"",
                isedit:false,
                id:FormData.id,
                boxtype:"Small 2 Part Pop up",
                content:FormData.message,
                mediacontent:FormData.mediacontent,
                link:FormData.link,
                offercloseoption:FormData.offercloseoption,
                heading:FormData.directionname,
                directiondegree:FormData.directiondegree,
                direction:FormData,
                usedata:true,

                enteredvalue: FormData.enteredvalue,
                celltype: FormData.celltype,
                objectpath: FormData.objectpath,
                checkvalue: FormData.checkvalue,
                operation: FormData.operation,
                
            }));
            return option;
        },

        getdirectionoption(FormData,dr,indexobj,checkstr){
            const option = JSON.parse(JSON.stringify({
                visible: true,
                sectionname:"",
                isedit:false,
                id:FormData.id,
                boxtype:"Small 2 Part Pop up",
                content:FormData.message,
                mediacontent:FormData.mediacontent,
                link:FormData.link,
                offercloseoption:FormData.offercloseoption,
                heading:FormData.directionname,
                directiondegree:FormData.directiondegree,
                direction:FormData,
                usedata:true,

                enteredvalue: FormData.enteredvalue,
                celltype: FormData.celltype,
                objectpath: FormData.objectpath,
                checkvalue: FormData.checkvalue,
                operation: FormData.operation,

            }));
            this.$set(option,'checkstr',checkstr);
            this.$set(option,'sectionindex',indexobj.sectionindex);
            if(indexobj.sectiondetailindex != undefined){
                this.$set(option,'sectiondetailindex',indexobj.sectiondetailindex);
            }
            if(indexobj.Bindex != undefined){
                this.$set(option,'Bindex',indexobj.Bindex);
            }
            if(indexobj.Cindex != undefined){
                this.$set(option,'Cindex',indexobj.Cindex);
            }
            if(indexobj.Dindex != undefined){
                this.$set(option,'Dindex',indexobj.Dindex);
            }
            this.$set(option,"uniqueid",indexobj.uniqueid);
            this.$set(option,"rowtype",indexobj.rowtype);
            Object.assign(option, dr);
            if(indexobj.rowtype == "section"){
                this.$set(option,"elementid",FormData.pagecell.cellname+indexobj.sectionindex);       
            }
            if(indexobj.rowtype == "sectiondetail"){
                this.$set(option,"elementid",FormData.pagecell.cellname+indexobj.sectionindex+indexobj.sectiondetailindex);
            }
            if(indexobj.rowtype == "A"){
                this.$set(option,"elementid",FormData.pagecell.cellname+indexobj.sectionindex+indexobj.sectiondetailindex+indexobj.Bindex);
            }
            if(indexobj.rowtype == "B"){
                this.$set(option,"elementid",FormData.pagecell.cellname+indexobj.sectionindex+indexobj.sectiondetailindex+indexobj.Bindex+indexobj.Cindex)
            }
            if(indexobj.rowtype == "C"){
                this.$set(option,"elementid",FormData.pagecell.cellname+indexobj.sectionindex+indexobj.sectiondetailindex+indexobj.Bindex+indexobj.Cindex+indexobj.Dindex)
            }
            option.validatedirectionsingle = (data)=>{this.validatedirectionsingle(data)}
            
            return option;
        },

        multicelllocationrulesmatch(celldirections, direction, errors) {
            let count = 0;

            for (let i = 0; i < celldirections.length; i++) 
            {

                let celldir = celldirections[i];
                let celldirectiondata = null;
                let cellvalue = null;
                
                if (celldir.cellname == "section" && celldir.sectiondr) 
                {
                    celldirectiondata = celldir.sectiondr;
                    cellvalue = celldir.enteredvalue;
                }
                else if (celldir.cellname == "role" && celldir.roledr)
                {
                    celldirectiondata = celldir.roledr;
                    cellvalue = celldir.enteredvalue;
                }
                else if (celldir.cellname == "sectionheading" && celldir.sectionheadingdr) 
                {
                    celldirectiondata = celldir.sectionheadingdr;
                    cellvalue = celldir.enteredvalue;
                }
                else if (celldir.cellname == "val" && celldir.valdr) 
                {
                    celldirectiondata = celldir.valdr;
                    cellvalue = celldir.enteredvalue;
                }
                else if (celldir.cellname == "crtn" && celldir.crtndr) 
                {
                    celldirectiondata = celldir.crtndr;
                    cellvalue = celldir.enteredvalue;
                }
                else if (celldir.cellname == "datestarted" && celldir.datestarteddr) 
                {
                    celldirectiondata = celldir.datestarteddr;
                    cellvalue = celldir.enteredvalue;
                }
                else if (celldir.cellname == "description" && celldir.descriptiondr) 
                {
                    celldirectiondata = celldir.descriptiondr;
                    cellvalue = celldir.enteredvalue;
                }
                else if (celldir.cellname == "sectiondetailbill" && celldir.sectiondetailbilldr) 
                {
                    celldirectiondata = celldir.sectiondetailbilldr;
                    cellvalue = celldir.sectiondetailbilldr.enteredvalue;
                }
                else if (celldir.cellname == "sectiondetailtakeoff" && celldir.sectiondetailtakeoffdr)
                {
                    celldirectiondata = celldir.sectiondetailtakeoffdr;
                    cellvalue = celldir.sectiondetailtakeoffdr.enteredvalue;
                }
                else if (celldir.cellname == "endnotes" && celldir.endnotesdr) 
                {
                    celldirectiondata = celldir.endnotesdr;
                    cellvalue = celldir.enteredvalue;
                }
                else if (celldir.cellname == "ascopebill" && celldir.ascopebilldr) 
                {
                    celldirectiondata = celldir.ascopebilldr;
                    cellvalue = celldir.ascopebilldr.enteredvalue;
                }
                else if (celldir.cellname == "ascopetakeoff" && celldir.ascopetakeoffdr) 
                {
                    celldirectiondata = celldir.ascopetakeoffdr;
                    cellvalue = celldir.ascopetakeoffdr.enteredvalue;
                }
                else if (celldir.cellname == "bscopebilldr" && celldir.bscopebilldr)
                {
                    celldirectiondata = celldir.bscopebilldr;
                    cellvalue = celldir.bscopebilldr.enteredvalue;
                }
                else if (celldir.cellname == "bscopetakeoffdr" && celldir.bscopetakeoffdrdr) 
                {
                    celldirectiondata = celldir.bscopetakeoffdrdr;
                    cellvalue = celldir.bscopetakeoffdrdr.enteredvalue;
                }
                else if (celldir.cellname == "cscopebilldr" && celldir.cscopebilldr)
                {
                    celldirectiondata = celldir.cscopebilldr;
                    cellvalue = celldir.cscopebilldr.enteredvalue;
                }
                else if (celldir.cellname == "cscopetakeoffdr" && celldir.cscopetakeoffdrdr) 
                {
                    celldirectiondata = celldir.cscopetakeoffdrdr;
                    cellvalue = celldir.cscopetakeoffdrdr.enteredvalue;
                }
                

                if (celldirectiondata) 
                {
                    const  dr = this.getformat(celldirectiondata);
                    let ddata = this.getCellValueAndRegexRules(celldirectiondata, cellvalue);
                    
                    // may be remove this (cellvalue) condition because Amal want to check Extra spaces and Empty string(NULL).
                    if (cellvalue && this.$directionutility.directionvaluematch(ddata) == false) 
                    {
                        count++;
                    }
                }
            }

            if (direction.directioncelldetails.length == count) 
            {
                errors.forEach(x => {
                    x.ismatchedrulecell = true;
                });

                return true;
            }
            else 
            {
                return false;
            }
        },
    },
    // destroyed() {
    //     // this.$store.state.checklistids.ismsg = false;
    // },
    created() {
        const params = new URLSearchParams(window.location.search);
        if (params.size > 0) {
            let projectid = parseInt(params.get('projectid'));
            let partid = parseInt(params.get('partid'));
            let sectionid = parseInt(params.get('sectionid'));

            if (isNaN(projectid) || isNaN(partid) || isNaN(sectionid)) {
                this.$alert("One or more parameters are not valid numbers", 'Invalid URL');
            } else {
                this.urlparams = { projectid: projectid, partid: partid, sectionid: sectionid };                
                this.Similar.projectids = this.urlparams;
                this.$bus.$emit('addprojectpart', null);
            }
        }
        if (this.ismsg == true) {
            this.Similar.projectdisabled = true;
            this.Similar.partdisabled = true;
            this.sectiondisabled = true;
        } else {
            this.Similar.projectdisabled = false;
            this.Similar.partdisabled = false;
            this.sectiondisabled = false;
        }
        this.$bus.$on("setuser", (user) => {
            this.user = user;
        });
    }
}
</script>
<style>
.fixwidthchecked {
    height: 50px;
    min-height: 20px;
    overflow-y: scroll;
    width: 293px;
}

.Checklist_Section_width {
    max-width: 920px;
    width: 100%;
}

.el-table .section-format {
    font-size: medium;
    font-weight: 600;
}
.islinethrough{
    text-decoration: line-through;
}
.var_crt {
    background-color: lightgray;
    margin: 2px;
    padding: 2px;
    /* border: 1px solid; */
    font-weight: bold;
}

.padding7TB {
    padding: 5px !important;
}

.sectionfont_A {
    font-weight: bold;
    font-size: 14px;
}

.sectionfont_B {
    font-weight: bold;
    font-size: 13px;
}

.sectionfont_C {
    font-weight: bold;
    font-size: 12px;
}
</style>
