<template>
    <div >
        <div class="fixdheader">
        <el-row>
            <el-col :span="12" class="headerstyle"> <span >Edit Tracker</span> </el-col>
        </el-row>
        <el-row>
            <span class="edittrackerwidth75 left">
                <strong>Origin:</strong>
            </span>
            <span>
                <projectselectpart @projectsectionchange="leftprojectsectionchange" :remember="false"></projectselectpart>
            </span>
        </el-row>
        <!-- <div class="spaceBetween" /> -->
        <el-row>
            <span class="edittrackerwidth75 left">
                <strong>Outcome:</strong>
            </span>
            <span>
                <projectselectpart @projectsectionchange="rightprojectsectionchange"></projectselectpart>
            </span>
        </el-row>
         <hr class="hrstyle" />
        </div>
        <div v-show="detail">
            <div style="width:60px; float:left">
                <el-button :class="expandcollapsetext == '+' ? 'icon-expand-btn-width-pluse' : 'icon-expand-btn-width-minus'"  type="info" @click="expandcollapse">{{expandcollapsetext}}</el-button>
            </div>
            <el-table ref="templatetable" v-show="showtemplates" :data="getprojecttemplates"
                      class="fullwidth"
                      :expand-row-keys="expandedrows"
                      row-key="id"
                      @expand-change="expandchange"
                      v-loading="loading"
                      :row-class-name="ptclass"
                      :show-header="true">
                <el-table-column type="expand" width="45">
                    <template slot-scope="pt" class="fullwidth">
                        <!--nested table-->
                        <el-table :data="pt.row.projecttemplatedetails"
                                  class="fullwidth"
                                  row-key="id"
                                  :row-class-name="ptdclass"
                                  :cell-class-name="cellClassName"
                                  ref="templatedetailtable">
                            <el-table-column label="123" prop="srno" width="45">
                                <template slot-scope="scope">
                                    {{scope.row.srno}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="category" label="Work Classification">
                                <template slot-scope="scope">
                                    {{scope.row.category}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="scope.row.bqcodelibraryid"
                                             label="BQ Code"
                                             width="360">
                                <template slot-scope="scope">
                                    {{scope.row.bqcodelibrary.bqcode}}
                                </template>
                            </el-table-column>
                            <el-table-column label="Dim" prop="dim" width="75" align="center">
                                <template slot-scope="scope">
                                    {{scope.row.dim}}
                                </template>
                            </el-table-column>
                            <el-table-column label="Qty" prop="qty" width="65" align="right">
                                <template slot-scope="scope">
                                    {{scope.row.qty}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="unit" label="Unit" width="70">
                                <template slot-scope="scope">
                                    {{scope.row.unit}}
                                </template>
                            </el-table-column>
                            <el-table-column label="Asterisk" prop="asterisik" min-width="100">
                                <template slot-scope="scope" min>
                                    {{scope.row.asterisk}}
                                </template>
                            </el-table-column>
                            <el-table-column label="Specification" prop="specification">
                                <template slot-scope="scope">
                                    {{scope.row.specification}}
                                </template>
                            </el-table-column>
                            <el-table-column label="End Notes" prop="other">
                                <template slot-scope="scope">
                                    {{scope.row.other}}
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </el-table-column>
                <el-table-column label="MC_T Name"
                                 prop="template.templatename">
                    <template slot-scope="scope">
                        <div>
                            {{scope.row.template? scope.row.template.templatename :  scope.row.label }}
                            <div v-if="scope.row.unit != scope.row.measuredunit" style="color:red">The unit does not match to measured unit {{scope.row.measuredunit}}</div>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column label="Subject" prop="subject" width="360">
                </el-table-column>

                <el-table-column label="" prop="" width="75">
                </el-table-column>

                <el-table-column label="Qty" prop="qty" width="65" align="right">
                    <template slot-scope="scope">
                        {{scope.row.qty | to2decimal}}
                    </template>
                </el-table-column>

                <el-table-column label="Unit" prop="template.unit" width="70">
                </el-table-column>

                <el-table-column label="TO-MC_T Match" prop="" min-width="100">
                    <template slot-scope="scope">
                        {{ (scope.template && scope.template.templatename !=  scope.row.label) ? 'Not Matched &#10007;' : 'Matched &#10004;' }}
                    </template>
                </el-table-column>

                <el-table-column label="Complete" prop="readytobill" align="center">
                    <template slot-scope="scope">
                        {{ (scope.row.readytobill != true) ? '&#10007;' : '&#10004;' }}
                    </template>
                </el-table-column>
                <!--this empty column is for table column alignment-->
                <el-table-column label="" prop="other" aligh="right">
                    <template slot-scope="scope">
                    </template>
                </el-table-column>
            </el-table>

            <el-table v-show="showschedules" :data="getprojectschedules"
                      class="fullwidth"
                      :expand-row-keys="expandedrows"
                      row-key="id"
                      @expand-change="expandchange"
                      v-loading="loading"
                      :row-class-name="ptclass"
                      ref="scheduletable">
                <el-table-column type="expand" :default-expand-all="true">
                    <template slot-scope="pt">
                        <!--nested table-->
                        <el-table :data="pt.row.projectscheduledetails"
                                  class="fullwidth"
                                  row-key="id"
                                  :row-class-name="ptdclass"
                                  :cell-class-name="cellClassName"
                                  ref="scheduledetailtable">
                            <el-table-column label="Item Name" prop="itemname"></el-table-column>
                            <el-table-column prop="bqcodelibrary.specification" label="SP" width="30" align="center"> </el-table-column>
                            <el-table-column label="BQ Code" prop="bqcocelibraryid"></el-table-column>
                            <el-table-column prop="bqcodelibraryid"
                                             label="BQ Code Mapped"
                                             width="360">
                                <template slot-scope="scope">
                                    {{scope.row.bqcodelibrary.bqcode}}
                                </template>
                            </el-table-column>

                            <el-table-column label="Qty" prop="qty" width="80"></el-table-column>
                            <el-table-column label="unit" prop="unit" width="100"></el-table-column>
                            <el-table-column label="Asterisk" prop="asterisik">
                                <template slot-scope="scope">
                                    {{scope.row.asterisk}}
                                </template>
                            </el-table-column>
                            <el-table-column label="Specification" prop="specification">
                                <template slot-scope="scope">
                                    {{scope.row.specification}}
                                </template>
                            </el-table-column>
                            <el-table-column label="End Notes" prop="other">
                                <template slot-scope="scope">
                                    {{scope.row.other}}
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </el-table-column>
                <el-table-column label="MC Name"
                                 prop="mcname">
                </el-table-column>

                <el-table-column prop="template"
                                 label="NRM Section">
                    <template slot-scope="scope">
                        {{scope.row.billcompilecode.description}}
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    import projectselectpart from './projectselectpart.vue';

    export default {
        data() {
            return {
                detail: false,
                leftproject: { id: null, projectname: '', library: '' },
                leftsectionid: null,
                rightproject: { id: null, projectname: '', library: '' },
                rightsectionid: null,
                showtemplates: false,
                showschedules: false,
                detailform: {
                    left: {},
                    right: {}
                },
                expandedrows: [],
                expandcollapsetext: "",
                loading: false
            }
        },
        computed: {
            getprojecttemplates: function () {
                if (!this.detailform.right.projecttemplates)
                    return [];
                //var pts = this.detailform.right.projecttemplates.filter((pt) => {
                //    if (this.search) {
                //        return pt.label.toLowerCase().includes(this.search.toLowerCase());
                //    }
                //    else {
                //        return true;
                //    }
                //});
                //return pts;
                return this.detailform.right.projecttemplates;
            },
            getprojectschedules: function () {
                if (!this.detailform.right.projectschedules)
                    return [];

                //return this.detailform.projectschedules.filter((pt) => {
                //    if (this.search) {
                //       return pt.mcname.toLowerCase().includes(this.search.toLowerCase());
                //    }
               //     else {
               //         return true;
                //    }
                //});
                return this.detailform.right.projectschedules;
            }
        },
        methods: {

            refresh: function () {
                if (this.leftproject.id && this.leftsectionid && this.rightproject.id && this.rightsectionid) {
                    this.getProject();
                }
                else {
                    this.detail = false;
                }
            },

            expandcollapse: function () {
                if (this.expandedrows.length > 0) {
                    this.expandedrows = [];
                }
                else {
                    if (this.showschedules) {
                        var templates = this.detailform.right.projectschedules;
                    }
                    else {
                        var templates = this.detailform.right.projecttemplates;
                    }
                    this.expandedrows = templates.map((v) => { return v.id });
                }
                var bigdash = new Option()
                bigdash.innerHTML = "&mdash;"
                this.expandcollapsetext = this.expandedrows.length ? bigdash.text : "+";
                this.showheader = (this.expandcollapsetext == "+")
            },

            expandchange: function (row, expandedRows) {
                //obsolete
                var expanded = expandedRows.map((v) => { return v.id });
                this.expandedrows = expanded;
                var bigdash = new Option()
                bigdash.innerHTML = "&mdash;"
                this.expandcollapsetext = this.expandedrows.length ? bigdash.text : "+"; //not sure why bigger dash displays like this.
                this.showheader = (this.expandcollapsetext == "+")
            },

            leftprojectsectionchange: function (Projectobj) {
                this.leftsectionid = Projectobj.sectionid;
                this.leftproject = Projectobj.project;
                this.refresh();
            },

            rightprojectsectionchange: function (Projectobj) {
                this.rightsectionid = Projectobj.sectionid;
                this.rightproject = Projectobj.project;
                this.refresh();
            },

            ptclass: function (row, rowIndex) {
                var cls = ["boldrow"];
                if (row.row.deleted == true) {
                    cls.push("red");
                }
                if (row.row.added == true) {
                    cls.push("green");
                }
                return cls.join(' ');
            },

            ptdclass: function (row, rowIndex) {
                var cls = [];
                if (row.row.deleted == true) {
                    cls.push("red");
                }
                if (row.row.added == true) {
                    cls.push("green");
                }
                return cls.join(' ');
            },

            editTrack: function () {
                var leftpts = this.detailform.left.projecttemplates;
                var rightpts = this.detailform.right.projecttemplates;
                //check for deletd templates:project in left but not in right
                leftpts.forEach((pt) => {
                    var pt2 = rightpts.find((pt1) =>  pt1.label == pt.label && pt1.subject == pt.subject && (pt1.qty == 0 && pt.qty == 0 || pt.qty > 0 && pt1.qty > 0) );
                    if (pt2 == null) {
                        pt.deleted = true;
                        rightpts.push(pt);
                    }
                })

                //check for deletd templates:project in left but not in right
                rightpts.forEach((pt) => {
                    if (pt.deleted != true) {
                        var pt2 = leftpts.find((pt1) => pt1.label == pt.label && pt1.subject == pt.subject && (pt1.qty == 0 && pt.qty == 0 || pt.qty > 0 && pt1.qty > 0));
                        if (pt2 == null) {
                            pt.added = true;
                        }
                        else {
                            pt.old = pt2;
                        }
                    }
                })

                rightpts.forEach((pt) => {
                    if (pt.old != null) {
                        //check for added row
                        pt.projecttemplatedetails.forEach(ptrr => {
                            ptrr.old = pt.old.projecttemplatedetails.find(ptrl => ptrl.bqcodelibraryid == ptrr.bqcodelibraryid && ptrl.category == ptrr.category);
                            if (ptrr.old == null) {
                                ptrr.added = true;
                            }
                        });

                        pt.old.projecttemplatedetails.forEach(ptrl => {
                            var ptrl1 = pt.projecttemplatedetails.find(ptrr => ptrr.bqcodelibraryid == ptrl.bqcodelibraryid && ptrr.category == ptrl.category);
                            if (ptrl1 == null) {
                                ptrl.deleted = true;
                                pt.projecttemplatedetails.push(ptrl);
                            }
                        });


                        //check for deleted row
                    }
                });

                //check for added templates: //project not in left but in right.


                //repeat the same procedure for project schedules
                var leftpss = this.detailform.left.projectschedules;
                var rightpss = this.detailform.right.projectschedules;

                leftpss.forEach((ps) => {
                    var ps2 = rightpss.find((ps1) => ps1.mcname == ps.mcname);
                    if (ps2 == null) {
                        ps.deleted = true;
                        rightpss.push(ps);
                    }
                })

                //check for deletd templates:project in left but not in right
                rightpss.forEach((ps) => {
                    if (ps.deleted != true) {
                        var ps2 = leftpss.find((ps1) => ps1.mcname == ps.mcname);
                        if (ps2 == null) {
                            ps.added = true;
                        } else {
                            ps.old = ps2;
                        }
                    }
                })

                rightpss.forEach((ps) => {
                    if (ps.old != null) {
                        //check for added row
                        ps.projectscheduledetails.forEach(psrr => {
                            psrr.old = ps.old.projectscheduledetails.find(psrl => psrl.itemname == psrr.itemname);
                            if (psrr.old == null) {
                                psrr.added = true;
                            }
                        });

                        ps.old.projectscheduledetails.forEach(psrl => {
                            var psrl1 = ps.projectscheduledetails.find(psrr => psrr.itemname == psrl.itemname);
                            if (psrl1 == null) {
                                psrl.deleted = true;
                                ps.projectscheduledetails.push(psrl);
                            }
                        });
                        //check for deleted row
                    }
                });

            },

            getProject: function () {
                var data = {
                    leftsection: this.leftsectionid,
                    rightsection: this.rightsectionid,
                    leftproject: this.leftproject.id,
                    rightproject: this.rightproject.id
                };
                this.$http.post('project/GetEditTracker', data)
                    .then(response => {
                        var json = JSON.parse(response.data);


                        var json = JSON.parse(response.data);
                        if (json.left.section && !json.left.section.status) {
                            json.left.section.status = '';
                        }
                        this.detailform = json;
                        this.attachments = [];
                        this.isnew = false;

                        if (this.showtemplates) {
                            //this.setdecimal();
                        }

                        this.loading = false;
                        if (this.expandedrows.length == 0 && this.expandedrows.length == 0) {
                            this.expandcollapse(); //expand all rows by default
                        }

                        this.showschedules = json.left.projectschedules.length > 0 || json.right.projectschedules.length > 0;
                        this.showtemplates = json.left.projecttemplates.length > 0 || json.right.projecttemplates.length > 0;;

                        //this.selectBillBQCode();
                        this.$bus.$emit('setscroll');




                        this.detailform = json;
                        this.attachments = [];
                        this.editTrack();
                        this.detail = true;
                        //this.checkQty();
                    }).catch(err => {
                        this.$bus.$emit('error', err);
                        //console.log(err);
                        //this.$alert(err.response.data, err.response.status + ' ' + err.response.statusText, { dangerouslyUseHTMLString: true });
                    });
            },

            cellClassName: function (param) {
                var cls = [];
                if (param.row.old != null) {
                    if (param.row[param.column.property] != param.row.old[param.column.property]) {
                        //if (param.column.property == "qty" && param.row.qty.toFixed(3) == param.row.qty.toFixed(3)) {
                            // float comparision doesn't work properly
                       // } else {
                            cls.push("lightblue");
                       // }
                    }
                }
                return cls.join(" ");
            },

            rowClick: function (row, event, column) {
                if (!row.bqcode)
                    return;
                this.$confirm('Would you like to edit this activity?', 'Move to edit?', {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                    type: '' //success
                }).then(() => {
                    window.setTimeout(() => {
                        this.$store.state.billbqcode = row.bqcode;
                        this.$store.state.billasterisk = row.asterisk;
                        this.$router.push('/templates');
                    }, 100);
                }).catch(() => {/*Cancel clicked*/ });;
            }
        },
        created: function () {
        },
        components: {
            'projectselectpart': projectselectpart,
        }
    }
</script>
<style>
    .bold {
        font-weight: bold;
    }

    .italic {
        font-style: italic;
    }
    .greenback {
        background-color: lightgreen
    }
    .redback {
        background-color: red
    }
    .blueback {
        background-color: blue
    }

    .red {
        color: red;
    }

    .green {
        color: green;
    }

    .lightblue {
        background-color: rgba(0, 100, 255, 0.25) !important;
    }
</style>
