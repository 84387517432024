<template>
    <div>
        <div class="fixdheader">
            <el-row style="width: 863px;">
                <span class="headerstyle">Sign Off - Sections</span>
                <span class="right">
                    <el-button icon="el-icon-back" type="info" @click="$router.go(-1)"
                               class="marginleft4px">Back</el-button>
                </span>
            </el-row>

            <el-row>
                <el-col :span="24">
                    <projectselectpart :lastsavedshow="true" @projectsectionchange="projectsectionchange">
                    </projectselectpart>
                </el-col>
            </el-row>

            <div class="spaceBetween">
                <el-row>
                    <div v-if="lastsavedinfo != null">
                        <div class="el-input el-input--mini">
                            <span class="bold"> {{ lastsavedinfo.library }}</span> <b>
                                Last
                                saved:
                            </b> {{ lastsavedinfo.lastsavedbyname }} {{ lastsavedinfo.lastsaved }} {{lastsavedinfo.lastsavedpage}}
                        </div>
                    </div>
                </el-row>
            </div>
        </div>

        <div v-if="detail" class="max-content-width-as-container border-left-SO">
            <el-row class="Width-max-content border-right-SO">
                <hr class="hrspacebetween hrstyle" />
                <hr class="hrspacebetween hrstyle" />
                <el-col class="width20char border-right-SO">
                    <div>
                        <div class="col1c headerSO">
                            Checking Actions
                        </div>
                        <div class="spaceBetween" />
                        <div class="spaceBetween" />
                        <hr class="hrspacebetween " />
                        <div class="col1c-context2">
                            01. Instructions
                        </div>

                        <div class="spaceBetween" />
                        <hr class="hrspacebetween " />
                        <div class="spaceBetween" />
                        <div class="col1c-context2">
                            02. Take-Off
                        </div>

                        <div class="bglightgray">
                            <hr class="hrspacebetween " />
                            <div class="spaceBetween" />
                            <div class="col1c-context2">
                                03. Checking Lists
                            </div>
                            <hr class="hrspacebetween " />
                        </div>

                        <div class="spaceBetween" />
                        <div class="col1c-context2">
                            04. Compiling
                        </div>

                        <div class="bglightgray">
                            <hr class="hrspacebetween " />
                            <div class="spaceBetween" />
                            <div class="col1c-context2">
                                05. CQC
                            </div>
                            <hr class="hrspacebetween " />
                        </div>

                        <div class="spaceBetween" />
                        <div class="col1c-context2">
                            06. Bill Check
                        </div>

                        <hr class="hrspacebetween " />
                        <div class="spaceBetween" />
                        <div class=" col1c-context2 headerSO">
                            Checks 01-06 Complete
                        </div>

                        <hr class="hrspacebetween " />
                        <div class="spaceBetween" />
                        <div class=" col1c-context2">
                            08. Signed off by
                        </div>

                        <hr class="hrspacebetween " />
                        <div class="spaceBetween" />
                        <div class=" col1c-context2">
                            09. Date
                        </div>

                        <hr class="hrspacebetween " />
                        <div class="spaceBetween" />
                        <div class=" col1c-context2">
                            10. Time
                        </div>

                        <hr class="hrspacebetween " />
                        <div class="spaceBetween" />
                        <div class=" col1c-context2">
                            11. Version
                        </div>

                        <hr class="hrspacebetween " />
                        <div class="spaceBetween" />
                        <div class=" col1c-context2 headerSO">
                            Update 08 - 11
                        </div>
                        <div class="spaceBetween" />
                    </div>
                    <hr class="hrspacebetween hrstyle" />
                    <hr class="hrspacebetween hrstyle" />
                </el-col>

                <el-col class="width20char border-right-SO ">
                    <div class="center">
                        <div class="col1c headerSO">
                            Bill Self Check
                        </div>
                        <div class="spaceBetween" />
                        <div class="spaceBetween" />
                        <hr class="hrspacebetween " />
                        <div class="spaceBetween" />
                        <div class="col1c-context2">
                            <el-checkbox id="analysis_signoff_sections_bill_self_check_instructions"
                                         :disabled="billerdisable || e1" @change="changed(form.biller)"
                                         v-model="form.biller.instructions">
                            </el-checkbox>
                        </div>

                        <hr class="hrspacebetween " />
                        <div class="spaceBetween" />
                        <div class="col1c-context2">
                            <el-checkbox id="analysis_signoff_sections_bill_self_check_takeoff"
                                         :disabled="billerdisable || e1" @change="changed(form.biller)"
                                         v-model="form.biller.takeoff">
                            </el-checkbox>
                        </div>
                        
                        <div class="bglightgray">
                            <hr class="hrspacebetween " />
                            <div class="spaceBetween" />
                            <div class="col1c-context2">
                                <el-checkbox id="analysis_signoff_sections_bill_self_check_checkinglists"
                                            :disabled="true || billerdisable || e1" @change="changed(form.biller)"
                                            v-model="form.biller.checkinglists">
                                </el-checkbox>
                            </div>
                            <hr class="hrspacebetween " />
                        </div>

                        <div class="spaceBetween" />
                        <div class="col1c-context2">
                            <el-checkbox id="analysis_signoff_sections_bill_self_check_compiling"
                                         :disabled="billerdisable || e1" @change="changed(form.biller)"
                                         v-model="form.biller.compiling">
                            </el-checkbox>
                        </div>
                        
                        <div class="bglightgray">
                            <hr class="hrspacebetween " />
                            <div class="spaceBetween" />
                            <div class="col1c-context2">
                                <el-checkbox id="analysis_signoff_sections_bill_self_check_rwccqc"
                                            :disabled="true || billerdisable || e1" @change="changed(form.biller)" v-model="form.biller.rwccqc">
                                </el-checkbox>
                            </div>
                            <hr class="hrspacebetween " />
                        </div>

                        <div class="spaceBetween" />
                        <div class="col1c-context2">
                            <el-checkbox id="analysis_signoff_sections_bill_self_check_billcheck"
                                         :disabled="billerdisable || e1" @change="changed(form.biller)"
                                         v-model="form.biller.billcheck">
                            </el-checkbox>
                        </div>
                        <hr class="hrspacebetween " />

                        <div class="spaceBetween" />
                        <div class="col1c-context2">
                            <el-checkbox id="analysis_signoff_sections_bill_self_check_checkscomplete"
                                         :disabled="billerdisable || e1" @change="selectAll(form.biller)"
                                         v-model="form.biller.checkscomplete">
                            </el-checkbox>
                        </div>
                        <hr class="hrspacebetween " />

                        <div class="spaceBetween" />
                        <div class="col1c-context2">
                            {{ form.biller.cs == null ? "" : form.biller.cs.loginname }}
                        </div>
                        <hr class="hrspacebetween " />

                        <div class="spaceBetween" />
                        <div class="col1c-context2">
                            {{ getdateformat(form.biller.signoffon) }}
                        </div>
                        <hr class="hrspacebetween " />

                        <div class="spaceBetween" />
                        <div class="col1c-context2">
                            {{ gettimeformat(form.biller.signoffon) }}
                        </div>
                        <hr class="hrspacebetween " />

                        <div class="spaceBetween" />
                        <div class="col1c-context2" style="padding-top: 0px;">
                            <el-select id="analysis_signoff_sections_bill_self_check_version" style="width:55%"
                                       v-model="form.biller.version" @change="getversion($event, 'biller')" filterable
                                       placeholder="Select">
                                <el-option v-for="item in form.biller.versions" :key="item.id" :label="item.v"
                                           :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <hr class="hrspacebetween " />

                        <div class="spaceBetween" />
                        <div class="col1c-context2" style="padding-top: 0px;">
                            <el-button type="primary" style=" width: 80px;"
                                       :disabled="user.id == form.external.signoffbyid || !editpermission"
                                       @click="cellchanged('analysis_signoff_sections_bill_self_check_update', () => { save(form.biller) }, false)">Update</el-button>
                        </div>
                        <div class="spaceBetween" />
                    </div>
                    <hr class="hrspacebetween hrstyle" />
                    <hr class="hrspacebetween hrstyle" />
                </el-col>

                <el-col class="width20char ">
                    <div class="center">
                        <div class="col1c headerSO">
                            External Check
                        </div>

                        <div class="spaceBetween" />
                        <div class="spaceBetween" />
                        <hr class="hrspacebetween " />
                        <div class="spaceBetween" />

                        <div class="col1c-context2">
                            <el-checkbox id="analysis_signoff_sections_external_check_instructions"
                                         :disabled="externaldisable || b1" @change="changed(form.external, 'instructionscolor')"
                                         v-model="form.external.instructions"
                                         :class="instructionscolor == true ? 'orangecheckbox1' : ''">
                            </el-checkbox>
                        </div>
                        <hr class="hrspacebetween " />

                        <div class="spaceBetween" />
                        <div class="col1c-context2">
                            <el-checkbox id="analysis_signoff_sections_external_check_takeoff"
                                         :disabled="externaldisable || b1" @change="changed(form.external, 'takeoffcolor')"
                                         v-model="form.external.takeoff" 
                                         :class="takeoffcolor == true ? 'orangecheckbox2' : ''">
                            </el-checkbox>
                        </div>
                        
                        <div class="bglightgray">
                            <hr class="hrspacebetween " />
                            <div class="spaceBetween" />
                            <div class="col1c-context2">
                                <el-checkbox id="analysis_signoff_sections_external_check_checkinglists"
                                            :disabled="true || externaldisable || b1" @change="changed(form.external)"
                                            v-model="form.external.checkinglists">
                                </el-checkbox>
                            </div>
                            <hr class="hrspacebetween " />
                        </div>

                        <div class="spaceBetween" />
                        <div class="col1c-context2">
                            <el-checkbox id="analysis_signoff_sections_external_check_compiling"
                                         :disabled="externaldisable || b1" @change="changed(form.external, 'compilingcolor')"
                                         v-model="form.external.compiling" 
                                         :class="compilingcolor == true ? 'orangecheckbox3' : ''">
                            </el-checkbox>
                        </div>
                        
                        <div class="bglightgray">
                            <hr class="hrspacebetween " />
                            <div class="spaceBetween" />
                            <div class="col1c-context2">
                                <el-checkbox id="analysis_signoff_sections_external_check_rwccqc"
                                            :disabled="true || externaldisable || b1" @change="changed(form.external)"
                                            v-model="form.external.rwccqc">
                                </el-checkbox>
                            </div>
                            <hr class="hrspacebetween " />
                        </div>

                        <div class="spaceBetween" />
                        <div class="col1c-context2">
                            <el-checkbox id="analysis_signoff_sections_external_check_billcheck"
                                         :disabled="externaldisable || b1" @change="changed(form.external, 'billcheckcolor')"
                                         v-model="form.external.billcheck" 
                                         :class="billcheckcolor == true ? 'orangecheckbox4' : ''">
                            </el-checkbox>
                        </div>
                        <hr class="hrspacebetween " />

                        <div class="spaceBetween" />
                        <div class="col1c-context2">
                            <el-checkbox id="analysis_signoff_sections_external_check_checkscomplete"
                                         :disabled="externaldisable || b1" @change="selectAll(form.external)"
                                         v-model="form.external.checkscomplete">
                            </el-checkbox>
                        </div>
                        <hr class="hrspacebetween " />

                        <div class="spaceBetween" />
                        <div class="col1c-context2">
                            {{ form.external.cs == null ? "" : form.external.cs.loginname }}
                        </div>
                        <hr class="hrspacebetween " />

                        <div class="spaceBetween" />
                        <div class="col1c-context2">
                            {{ getdateformat(form.external.signoffon) }}
                        </div>
                        <hr class="hrspacebetween " />

                        <div class="spaceBetween" />
                        <div class="col1c-context2">
                            {{ gettimeformat(form.external.signoffon) }}
                        </div>
                        <hr class="hrspacebetween " />

                        <div class="spaceBetween" />
                        <div class="col1c-context2" style="padding-top: 0px;">
                            <el-select id="analysis_signoff_sections_external_check_version" style="width:55%"
                                       v-model="form.external.version" @change="getversion($event, 'external')" filterable
                                       placeholder="Select">
                                <el-option v-for="item in form.external.versions" :key="item.id" :label="item.v"
                                           :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <hr class="hrspacebetween " />

                        <div class="spaceBetween" />
                        <div class="col1c contenttext" style="padding-top: 0px;">
                            <el-button type="primary" style=" width: 80px;"
                                       :disabled="user.id == form.biller.signoffbyid || !editpermission"
                                       @click="cellchanged('analysis_signoff_sections_external_check_update', () => { save(form.external) }, false)">Update</el-button>
                        </div>
                    </div>
                    <hr class="hrspacebetween hrstyle" />
                    <hr class="hrspacebetween hrstyle" />
                </el-col>
            </el-row>
        </div>

        <div class="spaceBetween" />
        <div>
            <el-button v-if="detail" type="primary" @click="addnewversion" :disabled="!editpermission">
                Create New
                Version
            </el-button>
        </div>
    </div>
</template>
<script>
    import moment from "moment";
    import projectselectpart from './projectselectpart.vue';
    export default {
        data() {
            return {
                lastsavedinfo: null,
                tabledata: [],
                detail: false,
                form: {
                    biller: {},
                    external: {}
                },
                user: this.$store.state.user,
                b1: false,
                e1: false,
                isexternalsignoff: false,
                instructionscolor: false,
                takeoffcolor: false,
                compilingcolor: false,
                billcheckcolor: false
            }
        },
        computed: {
            getdateformat() {
                return function (data) {
                    if (data) {
                        var d = new Date(data);
                        return moment(d).format("DD-MMM-YYYY");
                    }
                    return data;
                }
            },
            gettimeformat() {
                return function (data) {
                    if (data) {
                        var d = new Date(data);
                        return moment(d).format("HH:mm");
                    }
                    return data;
                }
            },
            billerdisable() {
                if (this.form.biller.versions != undefined) {
                    var b = Math.max.apply(Math, this.form.biller.versions.map(function (o) { return o.id; }))
                    if (b == this.form.biller.id) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
            },
            externaldisable() {
                if (this.form.external.versions != undefined) {
                    var b = Math.max.apply(Math, this.form.external.versions.map(function (o) { return o.id; }))
                    if (b == this.form.external.id) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
            },
        },

        methods: {
            projectsectionchange: function (Projectobj) {
                // if (Projectobj.lastsavedinfo.library != undefined) {
                    this.lastsavedinfo = Projectobj.lastsavedinfo;
                // }
                this.sectionid = Projectobj.sectionid;
                this.project = Projectobj.project;
                this.projectpart = Projectobj.part;
                this.refresh();
            },

            refresh: function () {
                if (this.project.id && this.sectionid) {
                    this.getproject();
                }
                else {
                    this.detail = false;
                }
            },

            selectAll: function (row) {
                row.edited = true;
                if (row.checkscomplete) {
                    row.instructions = true;
                    row.takeoff = true;
                    // row.checkinglists = true;
                    row.compiling = true;
                    // row.rwccqc = true;
                    row.billcheck = true;
                }
                if (row.checktype == "external") {
                    this.e1 = true;
                }
                if (row.checktype == "biller") {
                    this.b1 = true;
                }
            },

            changed(row, cellvalue) {
                row.edited = true;
                if (row.checktype == "external") {
                    this.e1 = true;
                    
                    if (cellvalue) {
                        this[cellvalue] = false;
                    }
                }
                if (row.checktype == "biller") {
                    this.b1 = true;
                }
            },

            cellchanged(cellaction, callback, cancall) {
                const app = this;
                this.$directionutility.setcomponent(app);
                if (cancall) {
                    callback();
                    this.canmatchcellid(() => { }, cellaction, "");
                } else {
                    this.canmatchcellid(callback, cellaction, "");
                }
            },

            save: function (data) {
                if (data.edited == true) {
                    if (data.version != null) {
                        var a = data.version.toString().split('.')[1];
                        var num = parseFloat(a) + 1;
                        var f = data.version.toString().split(".")[0];
                        var l = num < 10 ? "0" + num : num;
                        if (l < 10) {
                            data.version = f.toString() + "." + l.toString();
                        } else {
                            data.version = f.toString() + "." + l.toString();
                        }
                    } else {
                        data.version = "01.01";
                    }
                    data.id = 0;
                    this.showloading();
                    this.$http.post("/signoff/savesignoffnrmelement", data)
                        .then(response => {
                            this.hideloading();
                            this.$message({
                                showClose: true,
                                message: response.data.message,
                                type: "success"
                            });
                            this.getproject();
                            this.$signalr.checkingdialogboxheaderpage();
                        })
                        .catch((err) => {
                            this.hideloading();
                            this.$bus.$emit("error", err);
                        });
                }
                else {
                    this.$message({
                        showClose: true,
                        message: "Nothing to update", type: "info"
                    });
                }
            },

            addnewversion: function () {
                this.$confirm('Are you sure you want to Create New Version?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    if (this.form.biller.version != null && this.user.id == this.form.biller.signoffbyid) {
                        var billerv = this.form.biller.version.toString().split('.')[0];
                        var bsum = parseFloat(billerv) + 1;
                        bsum = bsum < 10 ? "0" + bsum.toString() : bsum.toString();
                        this.form.biller.version = bsum + ".00";
                    }
                    if (this.form.external.version != null && this.user.id == this.form.external.signoffbyid) {
                        var externalv = this.form.external.version.toString().split('.')[0];
                        var esum = parseFloat(externalv) + 1;
                        esum = esum < 10 ? "0" + esum.toString() : esum.toString();
                        this.form.external.version = esum + ".00";
                    }
                }).catch(() => {
                    this.$message({
                        showClose: true,
                        type: 'info',
                        message: 'canceled'
                    });
                });
            },

            getversion: function (id, name) {
                this.showloading();
                this.$http.get("signoff/getversion", { params: { id: id } })
                    .then(response => {
                        this.form[name] = response.data;
                        this.hideloading();
                    })
                    .catch((err) => {
                        this.hideloading();
                        this.$bus.$emit("error", err);
                    });
            },

            getproject: function () {
                this.showloading();
                var data = { id: this.project.id, projectpart: { id: this.projectpart.id }, section: { id: this.sectionid } };
                this.$http.post('signoff/getsignoffnrmelement', data)
                    .then(response => {
                        this.form.biller = response.data.find(x => x.checktype == "biller");
                        this.form.external = response.data.find(x => x.checktype == "external");
                        if (this.form.biller.signoffon == undefined) {
                            this.b1 = false;
                        }
                        if (this.form.external.signoffon == undefined) {
                            this.e1 = false;
                        }
                        if (this.form.biller.projectid == 0 || this.form.biller.projectid == undefined) {
                            this.form.biller.projectid = this.project.id;
                            this.form.biller.projectsectionid = this.sectionid;
                            this.form.biller.projectpartid = this.projectpart.id;
                        }
                        if (this.form.external.projectid == 0 || this.form.external.projectid == undefined) {
                            this.form.external.projectid = this.project.id;
                            this.form.external.projectsectionid = this.sectionid;
                            this.form.external.projectpartid = this.projectpart.id;
                        }

                        // this.form.external.views.forEach(v => {
                        //     if (v.name.includes("Take off")) {
                        //         this.form.external.takeoff = true;
                        //         this.form.external.takeoffviewmatched = true;
                        //     }
                        //     if (v.name.includes("Compiling")) {
                        //         this.form.external.compiling = true;
                        //         this.form.external.compilingviewmatched = true;
                        //     }
                        //     if (v.name.includes("Bill Check")) {
                        //         this.form.external.billcheck = true;
                        //         this.form.external.billcheckviewmatched = true;
                        //     }
                        // });
                        this.getexternalsignoffby();

                        this.hideloading();
                        this.detail = true;
                    }).
                    catch((err) => {
                        this.hideloading();
                        this.$bus.$emit("error", err);
                    });
            },

            getexternalsignoffby: function() {
                this.showloading();
                this.$http.get('signoff/getexternalsignoffby?sectionid='+this.sectionid, {})
                    .then(res => {
                        this.isexternalsignoff = res.data;
                        
                        if (this.isexternalsignoff) {
                            // this.form.external.instructions = true;
                            this.form.external.takeoff = true;
                            this.form.external.compiling = true;
                            this.form.external.billcheck = true;

                            this.instructionscolor = this.isexternalsignoff;
                            this.takeoffcolor = this.isexternalsignoff;
                            this.compilingcolor = this.isexternalsignoff;
                            this.billcheckcolor = this.isexternalsignoff;
                        }
                        this.hideloading();
                    }).
                    catch((err) => {
                        this.hideloading();
                        this.$bus.$emit("error", err);
                    });
            },
            
        },

        components: {
            'projectselectpart': projectselectpart
        },

        created() {
            const params = new URLSearchParams(window.location.search);
            if (params.size > 0) {
                this.$training.getprojectusingparam(params);
            }
        }
    }
</script>
<style>
    .container {
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
    }

    .col1c {
        border: 0px solid white;
        width: 100%;
        height: 30px;
        padding: 6px;
        vertical-align: middle;
    }

    .headertext {
        font-weight: bold;
        font-size: 14px;
        padding: 6px;
    }

    .contenttext {
        font-size: 14px;
        padding: 6px;
    }

    .border-left-SO {
        border-left: 0.5px solid #eee;
        border-collapse: collapse;
    }

    .border-right-SO {
        border-right: 0.5px solid #eee;
    }

    .border-bottom-SO {
        border-bottom: 0.5px solid #eee;
    }

    .width20char {
        width: 155px;
    }

    max-content-width-as-container {
        width: max-content !important;
    }

    .partname {
        padding: 6px;
        white-space: normal;
        width: 100%;
        height: 60px;
        border: 0px solid white;
    }

    .col1c-context2 {
        border: 0px solid white;
        width: 100%;
        height: 30px;
        padding: 6px;
        vertical-align: middle;
        font-size: 12px;
        color: rgb(96, 98, 102);
        white-space: normal;
    }

    .Width-max-content {
        width: 468px;
    }

    .orangecheckbox1 .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #ff7600;
        border-color: #ff7600;
    }

    .orangecheckbox2 .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #ff7600;
        border-color: #ff7600;
    }

    .orangecheckbox3 .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #ff7600;
        border-color: #ff7600;
    }

    .orangecheckbox4 .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #ff7600;
        border-color: #ff7600;
    }
</style>
