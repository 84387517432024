<template>
    <div>
        <div class="fullwidth height240px flexbox border2pxgray paddingtop5 padleft5px padright5px">
            <div class="flexbox flexcontent flex-direction-column overflow-auto scrollbar-width-none">
                <div v-if="currentrow.botdetail?.length">
                    <div v-for="(item, i) in currentrow.botdetail" :key="i" class="flexbox border2pxgray marginb5">
                        <span class="width150px flexcontent flex-shrink-zero overflow-hidden">
                            <div class="tabletexttop tablewordbreak table-cell-padding2 table-empty-block">
                                <el-table :data="[]" :header-cell-class-name="headerrowstyle" :border="true">
                                    <el-table-column align="center" width="34">
                                        <template #header>
                                            <!-- <i :class="item.isopened ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"></i> -->
                                            <i class="el-icon-arrow-down"></i>
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="item.displayname" width="115" />
                                </el-table>
                            </div>
                        </span>

                        <span class="flex-grow-3 overflow-auto">
                            <!-- table-empty-block -->
                            <div class="tabletexttop tablewordbreak table-cell-padding2">
                                <el-table :data="item.tabledata" :border="true" row-key="id"
                                    :span-method="arraySpanMethod" :header-cell-class-name="secondtableheadercellclass"
                                    :cell-class-name="tablecellcolor">
                                    <el-table-column prop="percentage" align="center" width="45"
                                        :formatter="percentageformatter">
                                        <template #header>
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                    class="flexbox width-37px height-21px" viewBox="0 0 24 24">
                                                    <path fill="#ffffff"
                                                        d="M18.08 17.8c-.46.13-.87.2-1.23.2c-1.2 0-2.01-.88-2.42-2.65h-.05c-.99 1.91-2.38 2.86-4.13 2.86c-1.31 0-2.36-.49-3.15-1.48S5.92 14.5 5.92 13c0-1.75.45-3.15 1.34-4.24s2.1-1.64 3.63-1.64c.82 0 1.56.23 2.2.68c.64.46 1.13 1.1 1.47 1.93h.04l.71-2.4h2.56l-2.14 5.32c.24 1.24.49 2.09.77 2.54c.24.45.58.68 1 .68c.24 0 .43-.04.6-.11zm-4.26-5.24c-.21-1.13-.55-2.01-1.01-2.61c-.45-.61-1-.91-1.63-.91c-.82 0-1.48.37-1.97 1.1c-.49.74-.71 1.65-.71 2.72c0 .98.19 1.79.62 2.45c.42.66.99.98 1.7.98c.6 0 1.15-.29 1.64-.84c.5-.57.91-1.4 1.24-2.49z" />
                                                </svg>
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="Project Document Name / Reference" prop="documentname"
                                        width="300">
                                    </el-table-column>

                                    <el-table-column label="Activity Description Captured" prop="description"
                                        min-width="300">
                                    </el-table-column>

                                    <el-table-column align="center" width="80">
                                        <template #header>
                                            <div>
                                                <span v-if="!(item.tabledata.length > 0 && item.iscorreted)">[ ]
                                                    Correct</span>
                                                <span v-else v-html="'[&#10004;] Correct'"></span>
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column align="center" width="130">
                                        <template #header>
                                            <div>
                                                <span v-if="(item.tabledata.length > 0 && item.iscorreted)">[ ] User to
                                                    Describe</span>
                                                <span v-else v-html="'[&#10004;] User to Describe'"></span>
                                            </div>
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="" width="5">
                                    </el-table-column>

                                    <el-table-column label="Yes" align="center" width="45">
                                        <template #default="s">
                                            <el-checkbox :border="true" :class="{
                                                'height24px padding5px fontsize8': true,
                                                'checkbordergrey checkbox-bordered-grey-checked': s.row.ischecked,
                                                'checkborderblack checkblack checkbox-bordered-black-checked': item.iscorreted && s.row.ischecked
                                            }" :value="item.iscorreted && s.row.ischecked"
                                                @change="changeactivitydescription($event, s.row, s.$index, item)">
                                            </el-checkbox>
                                        </template>
                                    </el-table-column>

                                    <el-table-column label="Markup" prop="markup" align="center" width="60">
                                        <template #default="scope">
                                            <div class="UR-Link">{{ scope.row.markup }} </div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </span>
                    </div>
                </div>

                <div v-else class="align-items-center tablefont bold center">
                    No data
                </div>
            </div>

            <div class="width30px flex-shrink-zero">
                <span class="floatright">
                    <button @click="closebox(currentrow)">&times;</button>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: { currentrow: Object },
    data() {
        return {
        };
    },

    methods: {
        headerrowstyle({ row, column, rowIndex, columnIndex }) {
            if (rowIndex === 0) {
                return 'background-color-class5 whitecolor';
            }
        },

        secondtableheadercellclass({ row, column, rowIndex, columnIndex }) {
            if (rowIndex === 0) {
                if (columnIndex == 0 || columnIndex == 6 || columnIndex == 7) {
                    return 'background-color-class1 whitecolor';
                }
                else if (columnIndex == 1) {
                    return 'background-color-class2 whitecolor';
                }
                else if (columnIndex == 2 || columnIndex == 3 || columnIndex == 4) {
                    return 'background-color-class3 blackcolor';
                }
            }
        },

        arraySpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 2) {
                return [1, 3];
            } else if (columnIndex === 3 || columnIndex === 4) {
                return [0, 0];
            }
            return [1, 1];
        },

        tablecellcolor({ columnIndex }) {
            if (columnIndex == 0) {
                return 'background-color-class4 blackcolor';
            }
        },

        percentageformatter(_row, _column, cellValue) {
            const val = parseFloat(cellValue);
            if (!isNaN(val)) {
                return `${val}%`;
            }
            else if (isNaN(val) && cellValue) {
                return cellValue;
            }
            return null;
        },

        changeactivitydescription(event, row, index, item) {
            for (let i = 0; i < item.tabledata.length; i++) {
                const element = item.tabledata[i];
                element.ischecked = false;
                if (i == index) {
                    element.ischecked = event;
                    this.currentrow[item.column] = row.description;
                }
            }

            item.iscorreted = true;
            const allUnchecked = item.tabledata.every(x => x.ischecked === false);
            if (allUnchecked) {
                item.iscorreted = false;
            }

            item = this.$training.Clone(item);
        },

        changeusermanually(item) {
            for (let i = 0; i < item.tabledata.length; i++) {
                const element = item.tabledata[i];
                element.ischecked = false;
            }
            item.iscorreted = false;
        },

        closebox(currentrow) {
            this.$emit("closebox", currentrow);
        },
    },

    created() { }
};
</script>